import TablesContainer from "./TablesContainer";

function MainTables() {
    return (
        <div className="container-fluid py-4 px-3">
            <div className="row">
                <div className="col-12">
                    <TablesContainer/>
                </div>
            </div>
        </div>
    );
}

export default MainTables;
