import React from 'react'

function MainAddNavButton(params) {
    const passActive = (e) => {
        e.preventDefault();
        params.passActive(params.type)
    }

    return (
        <label onClick={passActive} className={"btn btn-" + params.btnColor + " " + (params.active ? "active" : "")}>
            <input type="radio" name={params.type} id={params.type} checked={params.active}/> {params.label}
        </label>
    )
}

export default MainAddNavButton
