import MainAddNav from './MainAddNav'
import MainAddForms from "./MainAddForms"
import { useState } from "react"

function MainAdd() {
    const [active, setActive] = useState("driver")

    const changeActive = (name) =>{
        setActive(name);
    }
    return (
        <div className="row px-5 py-4">
            <MainAddNav passActive={changeActive} />
            <MainAddForms active={active} />
        </div>
    );
}

export default MainAdd;
