import React, { useState } from 'react'
import TextInput from '../../MainAddComps/Forms/CustomInputs/TextInput'
import SelectInput from '../../MainAddComps/Forms/CustomInputs/SelectInput'
import uuid from 'react-uuid'
import Swal from "sweetalert2/dist/sweetalert2.all"

function OtelExtraAddInline(params) {
    const [otelName, setOtelName] = useState("")
    const [otelId, setOtelId] = useState("")
    const [entry, setEntry] = useState("")
    const [exit, setExit] = useState("")
    const [confirmation, setConfirmation] = useState("")
    const [adultCount, setAdultCount] = useState(0)
    const [kidCount, setKidCount] = useState(0)
    const [kidAge, setKidAge] = useState(0)
    const [roomExtras, setRoomExtras] = useState([])
    const [roomType, setRoomType] = useState("")
    const [roomBuy, setRoomBuy] = useState("")
    const [roomSell, setRoomSell] = useState("")

    const handleOtel = (val) => {
        setOtelId(val.value)
        setOtelName(val.label)
    }
    const handleEntry = (val) => {
        setEntry(val)
    }
    const handleExit = (val) => {
        setExit(val)
    }
    const handleConfirmation = (val) => {
        setConfirmation(val)
    }
    const handleAdultCount = (val) => {
        setAdultCount(val)
    }
    const handleKidCount = (val) => {
        setKidCount(val)
    }
    const handleKidAge = (val) => {
        setKidAge(val)
    }
    const handleRoomType = (val) => {
        setRoomType(val.label)
    }
    const handleRoomPurchase = (val) => {
        setRoomBuy(val)
    }
    const handleRoomSale = (val) => {
        setRoomSell(val)
    }
    const addRoomExtra = (e) => {
        e.preventDefault()
        let newRoom = { roomType: roomType, buy: roomBuy, sell: roomSell }
        setRoomExtras([...roomExtras, newRoom])
    }
    const handleSubmit = (e) => {
        e.preventDefault()
        let people_count = { "Yetişkin Sayısı": adultCount, "Çocuk Sayısı": kidCount, "Çocuk Yaş": kidAge }
        let data = {
            rezervation_id: params.id, otel_name: otelName, otel_id: otelId, entry: entry, exit: exit,
            confirmation: confirmation, people_count: people_count, roomExtras: roomExtras
        }
        api.addOtelExtra(data).then(res => {
            if (res.data[0] == "success") {
                console.log("success", res.date)
                params.endAdding(res.data[1], params.id);
            } else {
                console.log("db err", res.data)
            }
        }).catch(err => {
            console.log("js err", res.data)
        })
    }

    const handleCancel = (e) => {
        e.preventDefault()
        params.endAdding("endAdd", 0)
    }

    return (
        <div className="bg-light" style={{
            display: "grid",
            gridTemplateColumns: "30% 70%",
            borderBottom: "1px solid #343a40",
            padding: "10px 0"
        }}>
            <b>Otel ismi: </b> <SelectInput style="small" type="otel" name="11table-otel-name" passValue={handleOtel} />

            <b>Giriş zamanı: </b> <TextInput style="small" type="text" name="11table-otel-entry" passValue={handleEntry} />

            <b>Çıkış zamanı: </b><TextInput style="small" passValue={handleExit} />

            <b>Konf. no: </b> <TextInput style="small" type="text" passValue={handleConfirmation} />

            <b>Yetişkin Sayısı: </b> <TextInput style="small" passValue={handleAdultCount} />

            <b>Çocuk Sayısı: </b> <TextInput style="small" passValue={handleKidCount} />

            <b>Çocuk Yaş: </b> <TextInput style="small" passValue={handleKidAge} />

            <b>Oda Konaklamaları:</b>

            <div style={{ gridColumnStart: "1", gridColumnEnd: "3" }} >
                <div style={{ display: "grid", gridTemplateColumns: "40% 60%" }} className="border pl-3 w-100">

                    <b>Oda Tipi: </b> <SelectInput style="small" type="room-type" otelId={otelId} name="otelextrainlineroomtype111" passValue={handleRoomType} />

                    <b>Gecelik Alış: </b> <TextInput style="small" passValue={handleRoomPurchase} />

                    <b>Gecelik Satış: </b> <TextInput style="small" passValue={handleRoomSale} />

                    <div></div><button className="btn btn-sm btn-warning mt-2" onClick={addRoomExtra}>Oda Ekle</button>
                </div>
            </div>
            {roomExtras.length > 0 ?
                <div style={{ gridColumn: "1 / 3" }}>
                    {roomExtras.map(re => {
                        return (
                            <div style={{ display: "flex", justifyContent: "space-between" }} key={uuid()}>
                                <div><b>Oda Tipi: </b> {re.roomType}</div>
                                <div><b>Alış: </b> {re.buy}</div>
                                <div><b>Satış: </b> {re.sell}</div>
                            </div>
                        )
                    })}
                </div> : null
            }
            <button onClick={handleSubmit} className="btn btn-sm btn-primary w-100 mt-2" style={{ gridColumn: "1 / 3" }}>Otel Konaklaması Ekle</button>
            <button onClick={handleCancel} className="btn btn-sm btn-danger w-100 mt-2" style={{ gridColumn: "1 / 3" }}>İptal</button>
        </div>
    )
}

export default OtelExtraAddInline
