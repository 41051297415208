import React, { useState } from 'react'
import TextInput from "../../MainAddComps/Forms/CustomInputs/TextInput"
import SelectInput from "../../MainAddComps/Forms/CustomInputs/SelectInput"
import RoomExtraFormInline from "../../MainAddComps/Forms/InlineForms/RoomExtraFormInline"
import TextArea from "../../MainAddComps/Forms/CustomInputs/TextArea"
import DatePicker from "react-datepicker"
import tr from "date-fns/locale/tr"
import api from '../../../api'
import uuid from 'react-uuid'

function CarExtra(params) {
    const [car, setCar] = useState({})
    const [driver, setDriver] = useState({})
    const [provider, setProvider] = useState({})
    const [haveDriver, setHaveDriver] = useState(false)
    const [haveProvider, setHaveProvider] = useState(false)
    const [startKm, setStartKm] = useState("")
    const [endKm, setEndKm] = useState("")
    const [buy, setBuy] = useState("")
    const [sell, setSell] = useState("")
    const [extras, setExtras] = useState("")
    const [pickup, setPickup] = useState(new Date())
    const [pickoff, setPickoff] = useState(new Date())

    const handleSubmit = (e) => {
        e.preventDefault();
        let data = {
            car_id: car.value,
            car_name: car.label,
            have_driver: haveDriver,
            driver_id: driver.value,
            driver_name: driver.label,
            have_provider: haveProvider,
            provider_id: provider.value,
            provider_name: provider.label,
            start_km: startKm,
            end_km: endKm,
            buy: buy,
            sell: sell,
            extras: extras,
            pickup: pickup.toLocaleString(),
            pickoff: pickoff.toLocaleString(),
            id: uuid()
        }
        params.passValue(data);
    }
    const handleCar = (val) => {
        setCar(val);
    }
    const handlePickup = (val) => {
        setPickup(val)
    }
    const handlePickoff = (val) => {
        setPickoff(val)
    }
    const toggleHaveDriver = () => {
        setHaveDriver(!haveDriver);
    }
    const handleDriver = (val) => {
        setDriver(val);
    }
    const handleStartKm = (val) => {
        setStartKm(val);
    }
    const handleEndKm = (val) => {
        setEndKm(val);
    }
    const toggleHaveProvider = () => {
        setHaveProvider(!haveProvider);
    }
    const handleProvider = (val) => {
        setProvider(val);
    }
    const handleBuy = (val) => {
        setBuy(val);
    }
    const handleSell = (val) => {
        setSell(val);
    }
    const handleExtras = (val) => {
        setExtras(val);
    }
    // roomExtras

    return (
        <div className="col-12 mt-4">
            <SelectInput type="car" name="car-car" label="Araba Seçiniz" passValue={handleCar} />
            <div style={{ zIndex: 0 }} className="custom-control custom-checkbox">
                <input type="checkbox" className="custom-control-input" onChange={toggleHaveDriver} checked={haveDriver} id="car2342" />
                <label className="custom-control-label" htmlFor="car2342">Sürücü Var Mı?</label>
            </div>
            <SelectInput isDisabled={!haveDriver} type="driver" name="car-driver" placeholder="Sürücü Seçiniz" passValue={handleDriver} />
            <div style={{ zIndex: 0 }} className="custom-control custom-checkbox">
                <input type="checkbox" className="custom-control-input" onChange={toggleHaveProvider} checked={haveProvider} id="provider2342" />
                <label className="custom-control-label" htmlFor="provider2342">Sağlayıcı Var Mı?</label>
            </div>
            <SelectInput isDisabled={!haveProvider} type="provider" name="car-provider" placeholder="Sağlayıcı Seçiniz" passValue={handleProvider} />
            <div className="form-group">
                <label >Teslim Alınış Tarihi Seçiniz</label>
                <DatePicker
                    selected={pickup}
                    onChange={handlePickup}
                    wrapperClassName="form-control"
                    className="form-control"
                    timeCaption="Zaman"
                    showTimeSelect
                    locale={tr}
                    timeFormat="HH:mm"
                    dateFormat="dd/MM/yyyy HH:mm"
                />
            </div>
            <div className="form-group">
                <label >Teslim Alınış Tarihi Seçiniz</label>
                <DatePicker
                    selected={pickoff}
                    onChange={handlePickoff}
                    wrapperClassName="form-control"
                    className="form-control"
                    timeCaption="Zaman"
                    showTimeSelect
                    locale={tr}
                    timeFormat="HH:mm"
                    dateFormat="dd/MM/yyyy HH:mm"
                />
            </div>
            <div className="form-row">
                <div className="col-12 col-md-6">
                    <TextInput type="text" label="Başlangıç Kilometresini Giriniz" placeholder="10.000 KM" name="car-start-km" passValue={handleStartKm} />
                </div>
                <div className="col-12 col-md-6">
                    <TextInput type="text" label="Alınış Kilometresini Giriniz" placeholder="12.000 KM" name="car-end-km" passValue={handleEndKm} />
                </div>
                <div className="col-12 col-md-6">
                    <TextInput type="text" label="Alış Fiyatı" placeholder="100.00" passValue={handleBuy} />
                </div>
                <div className="col-12 col-md-6">
                    <TextInput type="text" label="Satış Fiyatı" placeholder="110.00" passValue={handleSell} />
                </div>
            </div>
            <TextArea label="HGS ve Diğer Araç Ekstraları" passValue={handleExtras} />

            <div className="text-right">
                <button onClick={handleSubmit} className="btn btn-danger w-25 mb-4">Arac Kiralama Ekle</button>
            </div>
        </div>
    )
}

export default CarExtra
