import React, { useState } from 'react'
import TextInput from "../../MainAddComps/Forms/CustomInputs/TextInput"
import SelectInput from "../../MainAddComps/Forms/CustomInputs/SelectInput"
import RoomExtraFormInline from "../../MainAddComps/Forms/InlineForms/RoomExtraFormInline"
import TextArea from "../../MainAddComps/Forms/CustomInputs/TextArea"
import DatePicker from "react-datepicker"
import tr from "date-fns/locale/tr"
import api from '../../../api'
import uuid from 'react-uuid'

function OtelExtra(params) {
    const [otel, setOtel] = useState(0)
    const [entry, setEntry] = useState(new Date())
    const [exit, setExit] = useState(new Date())
    const [info, setInfo] = useState("")
    const [confNum, setConfNum] = useState("")
    const [roomExtras, setRoomExtras] = useState([])
    const [roomTypes, setRoomTypes] = useState([]);
    const [kidCount, setKidCount] = useState(0)
    const [kidAge, setKidAge] = useState(0)
    const [adultCount, setAdultCount] = useState(0)

    const handleSubmit = (e) => {
        e.preventDefault();
        let people_count = { "Yetişkin Sayısı": adultCount, "Çocuk Sayısı": kidCount, "Çocuk Yaş": kidAge }
        let data = { otel_id: otel.value, otel_name: otel.label, entry_time: entry.toLocaleString(), exit_time: exit.toLocaleString(), info: info, confNum: confNum, roomExtras: roomExtras, id: uuid(), people_count: people_count }
        params.passValue(data);
    }
    const handleOtel = (val) => {
        setOtel(val);
        getRoomTypes(val.value);
    }
    const handleEntry = (val) => {
        setEntry(val)
    }
    const handleExit = (val) => {
        setExit(val)
    }
    const handleInfo = (val) => {
        setInfo(val);
    }
    const handleConfNum = (val) => {
        setConfNum(val)
    }
    const handleRoomExtras = (val) => {
        setRoomExtras([...roomExtras, val]);
    }
    // people_count
    const handleKidCount = (val) => {
        setKidCount(val);
    }
    const handleKidAge = (val) => {
        setKidAge(val);
    }
    const handleAdultCount = (val) => {
        setAdultCount(val);
    }
    function deleteRoomExtra(e) {
        e.preventDefault()
        let id = e.target.name;
        let newRoomExtra = roomExtras;
        newRoomExtra = newRoomExtra.filter(res => { return res.id != id });
        setRoomExtras(newRoomExtra)
    }
    const getRoomTypes = (id) => {
        if (id) {
            api.getRoomTypes(id).then(res => {
                let options = [];
                options = res.data.map(d => {
                    return { label: d, value: d }
                })
                setRoomTypes(options);
            }).catch(err => {
                console.log("error", err);
            })
        }
    }
    // roomExtras

    return (
        <div className="col-12 mt-4">
            <SelectInput type="otel" name="rezervation-otel" label="Otel Seçiniz" passValue={handleOtel} />
            <div className="form-row">
                <div className="form-group col-12 col-md-6">
                    <label >Başlangıç Tarihi Seçiniz</label>
                    <DatePicker
                        selected={entry}
                        onChange={handleEntry}
                        wrapperClassName="form-control"
                        className="form-control"
                        timeCaption="Zaman"
                        locale={tr}
                        dateFormat="dd/MM/yyyy"
                    />
                </div>
                <div className="form-group col-12 col-md-6">
                    <label >Bitiş Tarihi Seçiniz</label>
                    <DatePicker
                        selected={exit}
                        onChange={handleExit}
                        timeCaption="Zaman"
                        locale={tr}
                        id="endDateExtra"
                        wrapperClassName="form-control"
                        className="form-control"
                        minDate={entry}
                        dateFormat="dd/MM/yyyy"
                    />
                </div>
            </div>
            <TextInput type="text" name="rezervation-confNum" label="Konfirmasyon Numarası" passValue={handleConfNum} />
            {/* People Count */}
            <label className="ml-1" htmlFor="">Kişi Sayısı</label>
            <div className="row">
                <div className="col-12 col-md-4">
                    <TextInput type="text" placeholder="Çocuk Sayısı" name="rezervation-kid-count" passValue={handleKidCount} />
                </div>
                <div className="col-12 col-md-4">
                    <TextInput type="text" placeholder="Çocuk Yaş" name="rezervation-kid-age" passValue={handleKidAge} />
                </div>
                <div className="col-12 col-md-4">
                    <TextInput type="text" placeholder="Yetişkin Sayısı" name="rezervation-adult-count" passValue={handleAdultCount} />
                </div>
            </div>

            <label className="ml-1" htmlFor="">Oda Extraları</label>
            <RoomExtraFormInline data={roomTypes} passValue={handleRoomExtras} />
            {roomExtras.map(res => {
                return (
                    <div key={uuid()} className="row p-0 m-0 mb-1 bg-info rounded">
                        <div className="col-4 pt-1"><small><b>Oda Tipi: </b><span>{res.roomType}</span></small></div>
                        <div className="col-4 pt-1"><small><b>Gecelik Alış: </b><span>{res.buy}</span></small></div>
                        <div className="col-3 pt-1"><small><b>Gecelik Satış: </b><span>{res.sell}</span></small></div>
                        <a className="btn btn-small btn-danger col-1" onClick={deleteRoomExtra} name={res.id}>x</a>
                    </div>
                )
            })}
            <TextArea name="rezervation-info" label="Bilgi" passValue={handleInfo} />
            <div className="text-right">
                <button onClick={handleSubmit} className="btn btn-danger w-25 mb-4">Otel Konaklaması Ekle</button>
            </div>
        </div>
    )
}

export default OtelExtra