import React, { useState } from 'react'
import FileInput from '../../MainAddComps/Forms/CustomInputs/FileInput'

function FileExtraAddInline(params) {
    const [files, setFiles] = useState([])

    const handleFiles = (val) => {
        setFiles(val)
        console.log(val)
    }
    const handleSubmit = (e) => {
        e.preventDefault()
        let fileData = new FormData()
        let data = [];
        for (let i = 0; i < files.length; i++) {
            fileData.append("files[]", files[i]);
            data.push({ path: URL.createObjectURL(files[i]), file_name: files[i].name })
        }
        fileData.append("rezervation_id", params.id)
        api.uploadFile(fileData).then(res1 => {
            console.log("file upload success", res1.data);

            params.endAdding(data, params.id)
        }).catch(err => {
            console.log("file err: ", err);
        })

    }

    const handleCancel = (e) => {
        e.preventDefault()
        params.endAdding("endAdd", 0)
    }
    return (
        <div>
            <FileInput passValue={handleFiles} style="small" name="rezervation-file" label="Eklenicek Dosyaları Seçiniz" />
            <button onClick={handleSubmit} className="btn btn-sm btn-primary w-100 mt-2">Dosyaları Ekle</button>
            <button onClick={handleCancel} className="btn btn-sm btn-danger w-100 mt-2">İptal</button>
        </div>
    )
}

export default FileExtraAddInline
