import React from 'react';
import ReactDOM from 'react-dom';
import 'bootstrap/dist/css/bootstrap.css'
import Header from "./Header"
import MainPage from "./MainPage"
import { useEffect, useState } from "react";

function Index(params) {
    const [page, setPage] = useState("table")

    const changePage = (val) =>{
        setPage(val);
    }
    return (
        <div className="container-fluid p-0">
            <Header passHeader={changePage} />
            <MainPage page={page} />
        </div>
    );
}

export default Index;

if (document.getElementById('index')) {
    ReactDOM.render(<Index />, document.getElementById('index'));
}
