import React, { useState } from "react"
import Tags from "@yaireo/tagify/dist/react.tagify"
import "@yaireo/tagify/dist/tagify.css"

function TagInput(params) {
    const [tags, setTags] = useState(params.initialValue ?? "")
    const handleChange = (e) => {
        e.persist()
        let newTags = e.target.value;
        if (newTags != "") {
            newTags = JSON.parse(newTags)
            newTags = newTags.map(t => t.value);
            params.passValue(newTags);
        } else {
            params.passValue([]);
        }
    }
    return (
        <div className="form-group bg-white">
            {params.label ? <label >{params.label}</label> : null}
            <Tags
                onChange={handleChange}
                name={params.name}
                id={params.name}
                value={tags}
                settings={{
                    editTags: {
                        clicks: 1, keepInvalid: false
                    }
                }}
            />
        </div>
    )
};
export default TagInput