import React, { useState } from 'react'
import OtelExtraForm from "./ExtraForms/OtelExtraForm"
import CarExtraForm from "./ExtraForms/CarExtraForm"
import TicketExtraForm from "./ExtraForms/TicketExtraForm"
import OtherExtraForm from "./ExtraForms/OtherExtraForm"

const ExtraFormCont = (params) => {

    const passOtel = (val) => {
        params.passExtras("otel", val);
    }
    const passCar = (val) => {
        params.passExtras("car", val);
    }
    const passTicket = (val) => {
        params.passExtras("ticket", val);
    }
    const passOther = (val) => {
        params.passExtras("other", val);
    }

    let activeForm = <OtelExtraForm extras={params.otelExtras} passValue={passOtel} />;
    if (params.active == "otel") {
        activeForm = <OtelExtraForm extras={params.otelExtras} passValue={passOtel} />
    } else if (params.active == "car") {
        activeForm = <CarExtraForm extras={params.carExtras} passValue={passCar} />
    } else if (params.active == "ticket") {
        activeForm = <TicketExtraForm extras={params.ticketExtras} passValue={passTicket} />
    } else if (params.active == "other") {
        activeForm = <OtherExtraForm extras={params.otherExtras} passValue={passOther} />
    } else {
        activeForm = <OtelExtraForm extras={params.otelExtras} passValue={passOtel} />
    }


    return (
        <div>
            {activeForm}
        </div>
    )
}

export default ExtraFormCont
