import React from "react";

const TableSelectButton = (params) => {
    const passActive = (e) =>{
        e.preventDefault();
        params.passActive(params.name);
    }

    return (
        <label onClick={passActive} className={"btn text-center col-12 col-lg-3 btn-sm rounded-0 btn-primary border border-bottom-0 border-dark " + (params.active ? "active" : "")}>
            <input type="radio" name={params.name} id={params.name} checked={params.active} />{params.name}
        </label>
    );
}

export default TableSelectButton;