import MainTables from "./MainTableComps/MainTables"
import MainAdd from "./MainAddComps/MainAdd"
import RezervationAdd from "./RezervationAddComps/RezervationAdd"
import Settings from "./SettingsComps/Settings"

import UnderConstRez from "./Other/UnderConstRez"
import UnderConstSet from "./Other/UnderConstSet"
import UnderConstTable from "./Other/UnderConstTable"


function MainPage(params) {
    let page = params.page;
    if (page == "table") {
        return (
            <div>
                <MainTables/>
            </div>
        );
    } else if (page == "add") {
        return (
            <div>
                <MainAdd />
            </div>
        );
    } else if (page == "rezervation") {
        return (
            <div>
                <RezervationAdd/>
            </div>
        );
    } else if (page == "settings") {
        return (
            <div>
                <Settings />
            </div>
        );
    }
}

export default MainPage;
