import React, { useEffect, useState } from "react";
import TextInput from "../CustomInputs/TextInput"

function ResponsibleFormInline(params) {

    const [name, setName] = useState("")
    const [mail, setMail] = useState("")
    const [phone, setPhone] = useState("")

    const handleSubmit = (e) => {
        e.preventDefault();
        let data = { name: name, mail: mail, phone: phone }
        params.passValue(data);
    }
    const handleName = (val) => {
        setName(val);
    }
    const handleMail = (val) => {
        setMail(val)
    }
    const handlePhone = (val) => {
        setPhone(val)
    }


    return (
        <div className="col-12 m-0 p-0">
            <div className="row">
                <div className="col-12 col-lg-3 px-2">
                    <TextInput type="text" name="provider" placeholder="Sorumlu Adı" passValue={handleName} />
                </div>
                <div className="col-12 col-lg-3 px-2">
                    <TextInput type="text" name="provider_mail" placeholder="Email" passValue={handleMail} />
                </div>
                <div className="col-12 col-lg-3 px-2">
                    <TextInput type="text" name="provider_phone" placeholder="Telefon" passValue={handlePhone} />
                </div>
                <div className="col-12 col-lg-3 px-2">
                    <button onClick={handleSubmit} type="submit" className="btn btn-info w-100">Ekle</button>
                </div>
            </div>
        </div>
    )
}

export default ResponsibleFormInline
