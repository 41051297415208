import React, { useState } from 'react'
import uuid from 'react-uuid';
import SelectInput from "../CustomInputs/SelectInput"
import TextInput from "../CustomInputs/TextInput"

function RoomExtraFormInline(params) {
    const [roomType, setRoomType] = useState("");
    const [buy, setBuy] = useState(0);
    const [sell, setSell] = useState(0);
    const [value, setValue] = useState({});

    const handleRoomType = (val) => {
        setRoomType(val);
    }
    const handleBuy = (val) => {
        setBuy(val);
    }
    const handleSell = (val) => {
        setSell(val);
    }
    const handleSubmit = (e) => {
        e.preventDefault();
        let value = { roomType: roomType.value, buy: buy, sell: sell, id: uuid() }
        params.passValue(value);
    }
    return (
        <div className="col-12 px-2">
            <div className="row">
                <div className="row col-12 col-md-11 px-0 mx-0">
                    <div className="col-12 col-md-4 px-2">
                        <SelectInput type="custom" placeholder="Oda Tipi Seçiniz" name="roomExtra-roomType" data={params.data} passValue={handleRoomType} />
                    </div>
                    <div className="col-12 col-md-4 px-2">
                        <TextInput type="text" placeholder="Gecelik Alış Fiyatı" name="roomExtra-buy" passValue={handleBuy} />
                    </div>
                    <div className="col-12 col-md-4 px-2">
                        <TextInput type="text" placeholder="Gecelik Satış Fiyatı" name="roomExtra-sell" passValue={handleSell} />
                    </div>
                </div>
                <div className="col-12 col-md-1 px-2">
                    <button className="btn btn-warning w-100" onClick={handleSubmit}>Ekle</button>
                </div>
            </div>
        </div>
    )
}

export default RoomExtraFormInline
