import api from "../api"

function Header(params) {
    const sendRequest = (e) => {
        e.preventDefault();
        api.createDatabase()
            .then(res => {
                console.log(res.data);
            })
            .catch(error => {
                console.log(error);
            });
    }

    const changeActive = (link) => {
        let tableLink = document.getElementById("nav-item-table")
        let addLink = document.getElementById("nav-item-add")
        let rezLink = document.getElementById("nav-item-rez")
        let setLink = document.getElementById("nav-item-set")
        let newLink = document.getElementById("nav-item-" + link)
        

        tableLink.classList.remove("active");
        addLink.classList.remove("active");
        rezLink.classList.remove("active");
        setLink.classList.remove("active");
        
        newLink.classList.add("active");
    }

    const goTables = (e) => {
        e.preventDefault()
        changeActive("table")
        params.passHeader("table");
    }

    const goAdd = (e) => {
        e.preventDefault()
        changeActive("add")
        params.passHeader("add");
    }

    const goRez = (e) => {
        e.preventDefault()
        changeActive("rez")
        params.passHeader("rezervation");
    }

    const goSettings = (e) => {
        e.preventDefault()
        changeActive("set")
        params.passHeader("settings");
    }


    return (
        <nav className="navbar navbar-expand-sm navbar-dark bg-dark px-5">
            <a className="navbar-brand">AkikTour App</a>
            <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
                <span className="navbar-toggler-icon"></span>
            </button>
            <div className="collapse navbar-collapse" id="navbarNav">
                <ul className="navbar-nav">
                    <li id="nav-item-table" className="nav-item active">
                        <a className="nav-link" onClick={goTables} href="#">Tablolar</a>
                    </li>
                    <li id="nav-item-add" className="nav-item">
                        <a className="nav-link" onClick={goAdd} href="#">Tanımlamalar</a>
                    </li>
                    <li id="nav-item-rez" className="nav-item">
                        <a className="nav-link" onClick={goRez} href="#">Rezervasyon Ekle</a>
                    </li>
                    <li id="nav-item-set" className="nav-item">
                        <a className="nav-link" onClick={goSettings} href="#">Ayarlar</a>
                    </li>
                </ul>
                {/* <button className="btn btn-light" onClick={sendRequest} >Send Request</button> */}
            </div>
        </nav>
    );
}

export default Header;
