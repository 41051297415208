import React, { useEffect, useState } from 'react'
import uuid from 'react-uuid'

function FileInput(params) {
    const [files, setFiles] = useState([])

    const handleChange = (e) => {
        setFiles([...files, ...e.target.files])
        console.log(files)
    }
    const deleteFile = (e, file) => {
        e.preventDefault();
        let newFiles = files;
        newFiles = newFiles.filter(f => { return f != file });
        setFiles(newFiles);
    }
    useEffect(() => {
        params.passValue(files);
    }, [files])


    return (
        <div style={{ zIndex: -10 }} className="form-group">
            <label htmlFor="">{params.label}</label>
            <div style={{ zIndex: 0 }} className="custom-file">
                <input onChange={handleChange} type="file" multiple className="custom-file-input" id={params.name} name={params.name} />
                <label className="custom-file-label d-block" htmlFor={params.name}>
                    {
                        files.length == 0 ? "Birden çok dosya seçebilirsiniz" :
                            files.length + " dosya seçildi"
                    }
                </label>
                {files.length == 0 ? null :
                    <div className="w-100 border rounded mt-2 px-3 py-2" style={{ fontSize: "13px" }}>
                        {params.style != "small" ?
                            Array.from(files).map(file => {
                                return (
                                    <div className="w-100 border-bottom border-light" key={uuid()}>
                                        <a target="_blank" href={URL.createObjectURL(file)}>{file.name}</a>
                                        <a className="float-right text-danger" href="#" onClick={(e) => deleteFile(e, file)} >Dosyayı Çıkar</a>
                                    </div>
                                )
                            }) :
                            Array.from(files).map(file => {
                                return (
                                    <div className="w-100 border border-light" key={uuid()}>
                                        <a className="w-100 d-block" target="_blank" href={URL.createObjectURL(file)}>{file.name}</a>
                                        <a className="w-100 text-right d-block text-danger" href="#" onClick={(e) => deleteFile(e, file)} >Dosyayı Çıkar</a>
                                    </div>
                                )
                            })
                        }
                    </div>
                }
            </div>
        </div>
    )
}

export default FileInput
