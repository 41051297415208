import React, { useEffect, useState } from "react";
import TextInput from "./CustomInputs/TextInput"
import Swal from "sweetalert2/dist/sweetalert2.all"
import TextArea from "./CustomInputs/TextArea"
import TagInput from "./CustomInputs/TagInput"
import ResponsibleFormInline from "./InlineForms/ResponsibleFormInline"
import uuid from "react-uuid";

function OtelForm(props) {

    const [name, setName] = useState("")
    const [mail, setMail] = useState("")
    const [phone, setPhone] = useState("")
    const [address, setAddress] = useState("")
    const [roomTypes, setRoomTypes] = useState([])
    const [responsibles, setResponsibles] = useState([])

    const handleSubmit = (e) => {
        e.preventDefault();
        let data = { name: name, email: mail, phone: phone, address: address, room_types: roomTypes, responsibles: responsibles }
        // console.log(data);
        api.createOtel(data).then(res => {
            if (res.data[0] == "success") {
                Swal.fire("Başarılı", "Otel kaydı tamamlandı", "success")
            } else {
                console.log(res.data)
                Swal.fire("Başarısız", "", "error")
            }
        }).catch(err => {
            console.log(err)
            Swal.fire("Başarısız", "", "error")
        })
    }
    const handleName = (val) => {
        setName(val);
    }
    const handleMail = (val) => {
        setMail(val)
    }
    const handlePhone = (val) => {
        setPhone(val)
    }
    const handleAddress = (val) => {
        setAddress(val)
    }
    const handleRoomTypes = (val) => {
        setRoomTypes(val);
    }
    const handleResponsibles = (val) => {
        setResponsibles([...responsibles, val]);
    }
    function deleteResponsible(e) {
        e.preventDefault()
        let name = e.target.name;
        let newRespons = responsibles;
        newRespons = newRespons.filter(res => { return res.name != name });
        console.log(newRespons, name, e.target);
        setResponsibles(newRespons)
    }

    return (
        <div className="col-12 mt-4">
            <form onSubmit={handleSubmit}>
                <TextInput type="text" name="otel" label="Otel Adı" passValue={handleName} />
                <TextInput type="mail" name="mail" label="Email" passValue={handleMail} />
                <TextInput type="text" name="phone" label="Telefon Numarası" placeholder="0212 255 25 25" passValue={handlePhone} />
                <TextArea name="address" label="Adres" passValue={handleAddress} />
                <TagInput name="otel-tags" label="Oda Tipleri" passValue={handleRoomTypes} />
                <div className="form-group">
                    <label>Sorumlular</label>
                    <ResponsibleFormInline passValue={handleResponsibles} />
                </div>
                {responsibles.map(res => {
                    return (
                        <div key={uuid()} className="row p-0 m-0 mb-1 bg-info rounded">
                            <div className="col-4 pt-1"><small><b>İsim: </b><span>{res.name}</span></small></div>
                            <div className="col-4 pt-1"><small><b>Email: </b><span>{res.mail}</span></small></div>
                            <div className="col-3 pt-1"><small><b>Tel: </b><span>{res.phone}</span></small></div>
                            <a className="btn btn-small btn-danger col-1" onClick={deleteResponsible} name={res.id}>x</a>
                        </div>
                    )
                })}
                <button type="submit" className="btn btn-primary float-right w-25">Onayla</button>
            </form>
        </div>
    )
}

export default OtelForm
