import React, { useState, useEffect } from "react";
import Select from 'react-select';

function SelectInput(params) {
    const [value, setValue] = useState(params.value ?? "");
    const [options, setOptions] = useState(0);
    const [otelId, setOtelId] = useState(0)

    const handleChange = (val) => {
        setValue(val);
    }

    useEffect(() => {
        // console.log(options)
        if (options == 0 || params.type == "room-type") {
            if (params.type == "customer") {
                api.getCustomers().then(res => {
                    let data = res.data;
                    data = data.map(val => {
                        return { value: val.id, label: val.name + " (#" + val.id + ")" }
                    })
                    setOptions(data);
                }).catch(err => {
                    // console.log(err)
                })

            } else if (params.type == "otel") {
                api.getOtels().then(res => {
                    let data = res.data;
                    data = data.map(val => {
                        return { value: val.id, label: val.name + " (#" + val.id + ")" }
                    })
                    setOptions(data);
                }).catch(err => {
                    // console.log(err)
                })

            } else if (params.type == "provider") {
                api.getProviders().then(res => {
                    let data = res.data;
                    data = data.map(val => {
                        return { value: val.id, label: val.name + " (#" + val.id + ")" }
                    })
                    if (params.noOption) {
                        data.unshift({ value: 0, label: "Sağlayıcı Yok" })
                    }
                    setOptions(data);
                }).catch(err => {
                    // console.log(err)
                })
            } else if (params.type == "extra-type") {
                let data = [
                    { value: "car", label: "Araba Kiralama" },
                    { value: "otel", label: "Otel Rezervasyon" },
                    { value: "flight", label: "Uçak Bileti" },
                    { value: "other", label: "Diğer" },
                ]
                setOptions(data);
            } else if (params.type == "rezervation") {
                api.getRezervations().then(res => {
                    let data = res.data;
                    // console.log(data);
                    data = data.map(val => {
                        return { value: val.id, label: val.name + " (#" + val.id + ")" }
                    })
                    setOptions(data);
                }).catch(err => {
                    // console.log(err)
                })
            } else if (params.type == "car") {
                api.getCars().then(res => {
                    let data = res.data;
                    console.log("getcar res", data);
                    data = data.map(val => {
                        return { value: val.id, label: val.name + ` (${val.plate}) `  }
                    })
                    setOptions(data);
                }).catch(err => {
                    console.log("get car err", err)
                })
            } else if (params.type == "driver") {
                api.getDrivers().then(res => {
                    let data = res.data;
                    // console.log(data);
                    data = data.map(val => {
                        return { value: val.id, label: val.name + " (#" + val.id + ")" }
                    })
                    if (params.noOption) {
                        data.unshift({ value: 0, label: "Sürücü Yok" })
                    }
                    setOptions(data);
                }).catch(err => {
                    // console.log(err)
                })
            } else if (params.type == "custom") {
                if (params.data.length > 0) {
                    setOptions(params.data);
                }
            } else if (params.type == "room-type") {
                if (params.otelId && params.otelId != "" && params.otelId != otelId) {
                    api.getRoomTypes(params.otelId).then(res => {
                        let options = [];
                        options = res.data.map(d => {
                            return { label: d, value: d }
                        })
                        setOptions(options);
                        setOtelId(params.otelId);
                    }).catch(err => {
                        console.log("error", err);
                    })
                }
            }

        }
    })

    useEffect(() => {
        params.passValue(value);
    }, [value])

    return (
        <div className={params.style == "small" ? "" : "form-group"}>
            {params.label ? <label id="aria-label" htmlFor={params.name}>{params.label}</label> : null}
            <Select
                isSearchable={true}
                options={options ?? []}
                onChange={handleChange}
                id={params.name}
                name={params.name}
                className="basic-single"
                styles={params.style == "small" ? {
                    container: (provided) => ({
                        ...provided,
                        minWidth: "110px",
                        width: "100%",
                        fontSize: "12px",
                        padding: "0",
                        lineHeight: "1"
                    })
                } : ""}
                placeholder={params.placeholder ?? "Arama..."}
                value={value}
                isDisabled={params.isDisabled ?? false}
            />
        </div>
    )
}

export default SelectInput
