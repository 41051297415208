import React, { useState, useEffect } from 'react'
import MainAddNavButton from './MainAddNavButton'
import uuid from 'react-uuid'

function MainAddNav(params) {
    const btnColor = "info"
    const [buttons, setButtons] = useState([
        { name: "otel", active: true, label: "Otel Ekle" },
        { name: "driver", active: false, label: "Sürücü Ekle" },
        { name: "car", active: false, label: "Araba Ekle" },
        { name: "provider", active: false, label: "Sağlayıcı Ekle" },
        { name: "customer", active: false, label: "Müşteri Ekle" },
    ])
    const changeActive = (type) => {
        let newButtons = buttons;
        newButtons = newButtons.map(btn => {
            btn.active = false;
            if(btn.name == type){
                btn.active = true;
            }
            return btn;
        })
        setButtons(newButtons);
    }
    useEffect(() => {
        let activeBtn = buttons.find(btn => btn.active).name
        params.passActive(activeBtn)
    }, [buttons])

    return (
        <div className="btn-group btn-group-toggle col-12" data-toggle="buttons">
            {buttons.map(button => {
                return (
                    <MainAddNavButton
                        key={uuid()}
                        passActive={changeActive}
                        type={button.name}
                        btnColor={btnColor}
                        active={button.active}
                        label={button.label}
                    />
                )
            })}
        </div>
    )
}

export default MainAddNav

