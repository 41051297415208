import React, { useState, useEffect } from 'react'
import uuid from 'react-uuid';
import CarExtra from "./CarExtra"

function CarExtraForm(params) {
    const [extras, setExtras] = useState(params.extras);

    const handleExtras = (val) => {
        setExtras([...extras, val]);
    }
    const deleteExtra = (e) => {
        e.preventDefault();
        let id = e.target.name;
        let newExtras = extras;
        newExtras = newExtras.filter(ext => { return ext.id != id })
        setExtras(newExtras);
    }

    useEffect(() => {
        params.passValue(extras);
    }, [extras])

    // car_name
    // driver_name
    // provider_name
    // start_km
    // end_km
    // buy
    // sell
    // pickup
    // id
    return (
        <div className="col-12">
            <CarExtra passValue={handleExtras} />
            <div className="row row-cols-1 row-cols-md-2 row-cols-md-3">
                {params.extras.map(ex => {
                    return (
                        <div key={uuid} className="col mb-3">
                            <div className="card border-dark" style={{ fontSize: "12px" }}>
                                <div className="card-header">
                                    <b> Araba İsmi: </b> {ex.car_name}
                                    <button name={ex.id} onClick={deleteExtra} className="btn btn-sm btn-danger float-right">Sil</button>
                                </div>
                                <div className="card-body text-dark">
                                    <p className="card-text"> <b> Teslim Alınış Tarihi: </b> {ex.pickup}</p>
                                    <p className="card-text"> <b> Başlangıç Kilometresi: </b> {ex.start_km}</p>
                                    <p className="card-text"> <b> Alınış Kilometresi: </b> {ex.end_km}</p>
                                    <p className="card-text"> <b> Gunluk Alış Fiyatı: </b> {ex.buy}</p>
                                    <p className="card-text"> <b> Gunluk Satış Fiyatı: </b> {ex.sell}</p>
                                    <p className="card-text"> <b> Sürücü: </b> {ex.have_driver ? ex.driver_name : "Sürücü Yok"}</p>
                                    <p className="card-text"> <b> Sağlayıcı: </b> {ex.have_provider ? ex.provider_name : "Sağlayıcı Yok"}</p>
                                    <p className="card-text"> <b> HGS ve Diğer Araç Extraları: </b> {ex.extras}</p>
                                </div>
                            </div>
                        </div>
                    )
                })}
            </div>
        </div>
    )
}

export default CarExtraForm
