import React, { useEffect, useState } from "react";
import TextInput from "./CustomInputs/TextInput"
import SelectInput from "./CustomInputs/SelectInput"
import Swal from "sweetalert2/dist/sweetalert2.all"
import TextArea from "./CustomInputs/TextArea";

function ProviderForm(props) {

    const [name, setName] = useState("")
    const [mail, setMail] = useState("")
    const [phone, setPhone] = useState("")

    const handleSubmit = (e) => {
        e.preventDefault();
        let data = {name: name, mail: mail, phone: phone}
        api.createProvider(data).then(res =>{
            Swal.fire("Başarılı", "Sağlayıcı kaydı tamamlandı", "success")

        }).catch(err => {
            Swal.fire("Başarısız", "", "error")
        })
    }
    const handleName = (val) => {
        setName(val);
    }
    const handleMail = (val) => {
        setMail(val)
    }
    const handlePhone = (val) => {
        setPhone(val)
    }


    return (
        <div className="col-12 mt-4">
            <form onSubmit={handleSubmit}>
                <TextInput type="text" name="provider" label="Sağlayıcı Adı" passValue={handleName}/>
                <TextInput type="text" name="provider_mail" label="Email" passValue={handleMail}/>
                <TextInput type="text" name="provider_phone" label="Telefon" passValue={handlePhone}/>
                <button type="submit" className="btn btn-primary float-right w-25">Onayla</button>
            </form>
        </div>
    )
}

export default ProviderForm
