import uuid from "react-uuid";
import React, { useEffect, useState } from "react";
import RezervationEditFormInline from "./InlineEditForms/RezervationEditFormInline"
import CarEditFormInline from "./InlineEditForms/CarEditFormInline"
import CustomerEditFormInline from "./InlineEditForms/CustomerEditFormInline"
import DriverEditFormInline from "./InlineEditForms/DriverEditFormInline"
import OtelEditFormInline from "./InlineEditForms/OtelEditFormInline"
import ProviderEditFormInline from "./InlineEditForms/ProviderEditFormInline"
import ResponsibleEditFormInline from "./InlineEditForms/ResponsibleEditFormInline"
import AddExtraInlineForm from "./InlineAddForms/AddExtraInlineForm"
import Swal from "sweetalert2/dist/sweetalert2.all"
import Infinite from "react-infinite"
import "./style/style.css"



const WindowTable = (params) => {
    const [data, setData] = useState(0)
    const [value, setValue] = useState(params.searchValue);
    const [edit, setEdit] = useState(false);
    const [editId, setEditId] = useState(0);
    const [deletedIds, setDeletedIds] = useState([]);
    const [isAdd, setIsAdd] = useState(false)
    const [addId, setAddId] = useState(0)
    const [addType, setAddType] = useState("")

    // console.log("table:data",data);
    let order = data[1] ?? [];
    let tab_name = order["name"] ?? "";
    order = order["order"] ?? "";
    order = order == "" ? {} : JSON.parse(order);

    let tabs = order["tabs"] ?? [];

    let cols_order = order["cols_order"] ?? [];
    let table = data[0] ?? [];
    console.log(tab_name, table[0])
    console.log("data: ", data)

    let storage_path = params.storagePath;

    // table.length > 0 ? console.log("otel_extras", table[0]["otel_extras"][0]) : null;
    // table.length > 0 ? console.log("car_extras", table[0]["car_extras"][0]) : null;
    // table.length > 0 ? console.log("other_extras", table[0]["other_extras"][0]) : null;

    // console.log("cols_order", cols_order);
    // console.log("table", table);
    // table.length > 0 ? console.log("otelExtra", table[0]["otel_extras"][0]) : null;
    // console.log("tabs", tabs);
    // console.log("order", order);

    useEffect(() => {

        setData(params.data);

    }, [params.data])

    useEffect(() => {
        setValue(params.searchValue)
    }, [params.searchValue])

    function goToRef(e) {
        e.preventDefault();
        params.passId(e.target.id);
    }

    const editRow = (e, id) => {
        e.preventDefault();
        setEdit(true);
        setEditId(id);
    }
    const endEditing = (indata, id) => {
        console.log("endEditing")
        console.log("indata", indata)
        if (indata == "endEdit") {
            console.log("fefe")
        } else if (tab_name != "rezervations") {
            let index = table.findIndex(row => row.id == id)
            table[index] = indata
            let newData = data
            newData[0] = table
            console.log("newData", newData)
            setData(newData)
        } else {
            let index = table.findIndex(row => row.id == id)
            table[index]["customer_name"] = indata["name"]
            table[index]["customer_id"] = indata["id"]
            let newData = data
            newData[0] = table
            console.log("newData", newData)
            setData(newData)
        }
        setEdit(false)
        setEditId(0)
    }
    const deleteRow = (e, id) => {
        e.preventDefault();
        Swal.fire({ title: "Silme Onay", html: "Silmek istediğinize emin misiniz", icon: "warning", showCancelButton: true }).then(res => {
            if (res.isConfirmed) {
                let data = { tab_name: tab_name, id: id }
                api.deleteRow(data).then(res => {
                    if (res.data[0] == "success") {
                        Swal.fire("Başarılı", "Silme işlemi başarıyla gerçekleşti", "success");
                        console.log("Silme Başarılı", res.data[1]);
                        setDeletedIds([...deletedIds, id])
                    } else {
                        Swal.fire("Başarısız", "Bir hata gerçekleşti", "error");
                        console.log("Silme Başarısız DB Error", res.data);
                    }
                }).catch(err => {
                    Swal.fire("Başarısız", "Bir hata gerçekleşti", "error");
                    console.log("Silme Başarısız JS Error", res.data)
                });
            }
        })
        // ### todo: write delete code using id
        // ### use sweetalert to confirm
        // ### create a controller to delete
        // ### and notify with sweetalert the deleting again
        // ### than refresh the page
    }

    const addExtra = (e, id, type) => {
        e.preventDefault;
        console.log("id: " + id, "type: " + type)
        setIsAdd(true)
        setAddId(id)
        setAddType(type)
    }

    const endAdding = (resData, id, type) => {
        if (resData != "endAdd") {
            let newData = data
            let index = table.findIndex(row => row.id == id)
            if (type == "file") {
                let tableIndex = table[index]
                tableIndex["files"] = [...table[index]["files"], ...resData]
                table[index] = tableIndex
                newData[0] = table
                console.log(newData)
                setData([...newData])

            } else {
                table[index] = resData
                newData[0] = table
                setData(newData)
            }
        }
        setIsAdd(false)
        setAddId(0)
        setAddType("")
    }
    
    let elementHeight = 120;
    let tr_height = 120;
    if (tab_name == "rezervations") {
        elementHeight = 500;
    }
    tr_height = 120;
    if(tab_name == "otels"){
        tr_height = 200;
        elementHeight = 200;
    }
   
    

    if (data != 0) {
        return (
            <table className="table table-bordered table-hover table-sm">
                <thead className="thead-dark">
                    <tr>
                        <th className="table-id">ID</th>
                        {tabs.map(tab => {
                            if(tab == "Oda Türleri"){
                                return <th style={{flexGrow: 3}} className={"table "} key={uuid()}>{tab}</th>

                            }else{

                                return <th className={"table "} key={uuid()}>{tab}</th>
                            }
                        })}
                        <th className="table-islem">İşlemler</th>
                    </tr>
                </thead>
                <tbody>
                    <Infinite containerHeight={500} elementHeight={elementHeight} preloadBatchSize={Infinite.containerHeightScaleFactor(100)}>
                        {table.map(row => {
                            if (deletedIds.includes(row["id"]) || row["isDeleted"]) {
                                return null
                            } else if (edit && editId == row["id"]) {
                                // # Tüm tanımlamalar ve rezervasyon için ayrı tablo içi formlar componentleri oluşturucam  
                                if (tab_name == "rezervations") {
                                    return <RezervationEditFormInline endEditing={endEditing} id={row["id"]} keys={Object.keys(row)} row={row} />
                                } else if (tab_name == "cars") {
                                    return <CarEditFormInline endEditing={endEditing} id={row["id"]} row={row} />
                                } else if (tab_name == "customers") {
                                    return <CustomerEditFormInline endEditing={endEditing} id={row["id"]} row={row} />
                                } else if (tab_name == "drivers") {
                                    return <DriverEditFormInline endEditing={endEditing} id={row["id"]} row={row} />
                                } else if (tab_name == "otels") {
                                    return <OtelEditFormInline endEditing={endEditing} id={row["id"]} row={row} />
                                } else if (tab_name == "providers") {
                                    return <ProviderEditFormInline endEditing={endEditing} id={row["id"]} row={row} />
                                } else if (tab_name == "responsibles") {
                                    return <ResponsibleEditFormInline endEditing={endEditing} id={row["id"]} row={row} />
                                }
                                // # Tab_name e göre if else içinde componentleri göstericem
                                // # Düzenleye basıldığı zaman diğer düzenle ve silleri kapatıcam 
                                // # Bu componentin içinde Düzenle/Sil yerine Onayla/İptal gelicek
                            } else {
                                let cond1 = value != "" && !Object.values(row).some(el => {
                                    if (typeof el != "number" && typeof el != "object") {
                                        return el.toLowerCase().includes(value.toLowerCase())
                                    }
                                    return false;
                                });
                                if (cond1) {
                                    return null
                                } else {
                                    if (tab_name == "rezervations") {
                                        return (
                                            <tr key={uuid()}>
                                                <th className="table-id">{"#" + row["id"]}</th>
                                                {Object.keys(row).map(key => {
                                                    if (key == "otel_extras") {
                                                        return <td key={uuid()} className="w-25">
                                                            {(isAdd && addId == row["id"] && addType == "otel") ? <AddExtraInlineForm endAdding={endAdding} id={row["id"]} type={addType} /> :
                                                                <div className="text-center border-bottom border-dark pt-1 pb-2">
                                                                    <button onClick={(e) => addExtra(e, row["id"], "otel")} className="btn btn-sm w-50 btn-primary">Ekle</button>
                                                                </div>
                                                            }
                                                            {row[key].map(e => {
                                                                return (
                                                                    <div key={uuid()}>
                                                                        <b>Otel ismi: </b> {e.otel_name}
                                                                        <br />
                                                                        <b>Giriş zamanı: </b> {e.entry_time}
                                                                        <br />
                                                                        <b>Çıkış zamanı: </b> {e.exit_time}
                                                                        <br />
                                                                        <b>Konfirmasyon no: </b> {e.confirmation_number}
                                                                        <br />
                                                                        <b>Kişi Sayısı: </b> {typeof (e.people_count) == "string" ? e.people_count : JSON.stringify(e.people_count).replace(/"/g, "").replace("{", "").replace("}", "").replace(/:/g, ": ").replace(/,/g, ", ")}
                                                                        <br />
                                                                        <b>Oda Konaklamaları:</b>
                                                                        <div className="w-100 row m-0">
                                                                            {e.room_extras.map(re => {
                                                                                return (
                                                                                    <div key={uuid()} className="border col-12 pr-2 mb-1">
                                                                                        <b>Oda Tipi: </b> {re.room_type}
                                                                                        <br />
                                                                                        <b>Gecelik Alış: </b> {re.purchase_price}
                                                                                        <br />
                                                                                        <b>Gecelik Satış: </b> {re.sale_price}
                                                                                    </div>
                                                                                )
                                                                            })}
                                                                        </div>
                                                                        <hr />
                                                                    </div>
                                                                )
                                                            })}
                                                        </td>
                                                    } else if (key == "car_extras") {
                                                        return <td key={uuid()} className="w-25">
                                                            {(isAdd && addId == row["id"] && addType == "car") ? <AddExtraInlineForm endAdding={endAdding} id={row["id"]} type={addType} /> :
                                                                <div className="text-center border-bottom border-dark pt-1 pb-2">
                                                                    <button onClick={(e) => addExtra(e, row["id"], "car")} className="btn btn-sm w-50 btn-primary">Ekle</button>
                                                                </div>
                                                            }
                                                            {row[key].map(e => {
                                                                return (
                                                                    <div key={uuid()}>
                                                                        <b>Araba ismi: </b> {e.car_name}
                                                                        <br />
                                                                        <b>Sürücü: </b> {e.driver_name}
                                                                        <br />
                                                                        <b>Sağlayıcı: </b> {e.provider_name}
                                                                        <br />
                                                                        <b>Alış Tarihi: </b> {e.pickup_date}
                                                                        <br />
                                                                        <b>Teslim Tarihi: </b> {e.pickoff_date}
                                                                        <br />
                                                                        <b>Başlangıç km: </b> {e.start_km}
                                                                        <br />
                                                                        <b>Teslim km: </b> {e.end_km} {/* MFC */}
                                                                        <br />
                                                                        <b>Alış Fiyatı: </b> {e.purchase_price}
                                                                        <br />
                                                                        <b>Satış Fiyatı: </b> {e.sale_price}
                                                                        <br />
                                                                        <b>Extra Ücretler: </b> {e.extra_charges}
                                                                        <hr />
                                                                    </div>
                                                                )
                                                            })
                                                            }
                                                        </td>
                                                    } else if (key == "other_extras") {
                                                        return <td key={uuid()} className="w-25">
                                                            {(isAdd && addId == row["id"] && addType == "other") ? <AddExtraInlineForm endAdding={endAdding} id={row["id"]} type={addType} /> :
                                                                <div className="text-center border-bottom border-dark pt-1 pb-2">
                                                                    <button onClick={(e) => addExtra(e, row["id"], "other")} className="btn btn-sm w-50 btn-primary">Ekle</button>
                                                                </div>
                                                            }
                                                            {row[key].map(e => {
                                                                return (
                                                                    <div key={uuid()}>
                                                                        <b>Extra ismi: </b> {e.name}
                                                                        <br />
                                                                        <b>Tarih: </b> {e.date}
                                                                        <br />
                                                                        <b>Alış fiyatı: </b> {e.purchase_price}
                                                                        <br />
                                                                        <b>Satış fiyatı: </b> {e.sell_price}
                                                                        <hr />
                                                                    </div>
                                                                )
                                                            })
                                                            }
                                                        </td>
                                                    } else if (key == "files") {
                                                        return <td key={uuid()} className="w-25 table-files">
                                                            {(isAdd && addId == row["id"] && addType == "file") ?
                                                                <AddExtraInlineForm endAdding={endAdding} id={row["id"]} type={addType} /> :
                                                                <div className="text-center border-bottom border-dark pt-1 pb-2">
                                                                    <button onClick={(e) => addExtra(e, row["id"], "file")} className="btn btn-sm w-50 btn-primary">Ekle</button>
                                                                </div>
                                                            }
                                                            {row[key].map(e => {
                                                                return (
                                                                    <div key={uuid()}>
                                                                        <a href={e.path} target="_blank">{e.file_name}</a>
                                                                    </div>
                                                                )
                                                            })
                                                            }
                                                        </td>
                                                    }
                                                    else if (cols_order.includes(key)) {
                                                        if (value == "") {
                                                            if (key.includes("_name"))
                                                                return <td className="table-customer" key={uuid()}>
                                                                    <a className="link link-primary"
                                                                        id={row[key] + "###" + key.split("_")[0]} onClick={goToRef} href="#" >
                                                                        {row[key]}
                                                                    </a>
                                                                </td>
                                                            else
                                                                return <td key={uuid()}>{row[key]}</td>
                                                        } else if (row[key].toString().toLowerCase().includes(value.toLowerCase())) {
                                                            if (key.includes("_name"))
                                                                return <td key={uuid()}><a className="link link-primary" href="#" onClick={goToRef}
                                                                    id={row[key] + "###" + key.split("_")[0]}><span className="font-weight-bold bg-success">{row[key]}</span></a></td>
                                                            else
                                                                return <td key={uuid()}><span className="font-weight-bold bg-success">{row[key]}</span></td>
                                                        } else {
                                                            if (key.includes("_name"))
                                                                return <td key={uuid()}><a className="link link-primary" href="#" onClick={goToRef}
                                                                    id={row[key] + "###" + key.split("_")[0]}>{row[key]}</a></td>
                                                            else
                                                                return <td key={uuid()}>{row[key]}</td>
                                                        }
                                                    } else {
                                                        return null
                                                    }
                                                })}
                                                <td className="table-islem1">
                                                    <div className="p-2">
                                                        <a href="#" onClick={(e) => editRow(e, row["id"])} className="btn btn-sm btn-primary w-100 mb-2">Düzenle</a>
                                                        <a href="#" onClick={(e) => deleteRow(e, row["id"])} className="btn btn-sm btn-danger w-100">Sil</a>
                                                    </div>
                                                </td>
                                            </tr>
                                        )
                                    } else {
                                        return (
                                            <tr style={{height: tr_height}} key={uuid()}>
                                                <th className="table-id">{"#" + row["id"]}</th>
                                                {Object.keys(row).map(key => {
                                                    if(key == "room_types"){
                                                        return <td style={{flexGrow: 3}} key={uuid()}>{row[key]}</td>
                                                    } else if (cols_order.includes(key)) {
                                                        if (value == "") {
                                                            if (key.includes("_name"))
                                                                return <td key={uuid()}>
                                                                    <a className="link link-primary"
                                                                        id={row[key] + "###" + key.split("_")[0]} onClick={goToRef} href="#" >
                                                                        {row[key]}
                                                                    </a>
                                                                </td>
                                                            else
                                                                return <td key={uuid()}>{row[key]}</td>
                                                        } else if (row[key].toString().toLowerCase().includes(value.toLowerCase())) {
                                                            if (key.includes("_name"))
                                                                return <td key={uuid()}><a className="link link-primary" href="#" onClick={goToRef}
                                                                    id={row[key] + "###" + key.split("_")[0]}><span className="font-weight-bold bg-success">{row[key]}</span></a></td>
                                                            else
                                                                return <td key={uuid()}><span className="font-weight-bold bg-success">{row[key]}</span></td>
                                                        } else {
                                                            if (key.includes("_name"))
                                                                return <td key={uuid()}><a className="link link-primary" href="#" onClick={goToRef}
                                                                    id={row[key] + "###" + key.split("_")[0]}>{row[key]}</a></td>
                                                            else
                                                                return <td key={uuid()}>{row[key]}</td>
                                                        }
                                                    } else {
                                                        return null
                                                    }
                                                })}
                                                <td className="table-islem1">
                                                    <div className="p-2">
                                                        <a href="#" onClick={(e) => editRow(e, row["id"])} className="btn btn-sm btn-primary w-100 mb-2">Düzenle</a>
                                                        <a href="#" onClick={(e) => deleteRow(e, row["id"])} className="btn btn-sm btn-danger w-100">Sil</a>
                                                    </div>
                                                </td>
                                            </tr>
                                        )
                                    }

                                }
                            }
                        })}
                    </Infinite>

                </tbody>
            </table>
        );
    } else {
        return <div></div>
    }
}

export default WindowTable;