import React, { useEffect, useState } from "react";
import TextInput from "./CustomInputs/TextInput"
import Swal from "sweetalert2/dist/sweetalert2.all"
import SelectInput from "./CustomInputs/SelectInput"

function CustomerForm(props) {

    const [name, setName] = useState("")
    const [mail, setMail] = useState("")
    const [phone, setPhone] = useState("")
    const [nationality, setNationality] = useState("")
    const [provider, setProvider] = useState([]);

    const handleSubmit = (e) => {
        e.preventDefault();
        let data = { name: name, email: mail, phone: phone, nationality: nationality, provider: provider.value }
        api.createCustomer(data).then(res => {
            if (res.data[0] == "success") {
                Swal.fire("Başarılı", "Otel kaydı tamamlandı", "success")
            } else {
                console.log(res.data)
                Swal.fire("Başarısız", "", "error")
            }
        }).catch(err => {
            console.log(err)
            Swal.fire("Başarısız", "", "error")
        })
    }
    const handleName = (val) => {
        setName(val);
    }
    const handleMail = (val) => {
        setMail(val)
    }
    const handlePhone = (val) => {
        setPhone(val)
    }
    const handleNationality = (val) => {
        setNationality(val);
    }
    const handleProvider = (val) => {
        setProvider(val)
    }

    return (
        <div className="col-12 mt-4">
            <form onSubmit={handleSubmit}>
                <TextInput type="text" name="customer" label="Müşteri Adı" passValue={handleName} />
                <TextInput type="mail" name="customer-mail" label="Email" passValue={handleMail} />
                <TextInput type="text" name="customer-phone" label="Telefon Numarası" placeholder="0212 255 25 25" passValue={handlePhone} />
                <TextInput type="text" name="customer-nationality" label="Uyruk" passValue={handleNationality} />
                <SelectInput type="provider" name="customer-provider" label="Sağlayıcı Seçiniz" passValue={handleProvider} />
                <button type="submit" className="btn btn-primary float-right w-25">Onayla</button>
            </form>
        </div>
    )
}

export default CustomerForm
