import React, { useEffect, useState } from "react";
import TextInput from "./CustomInputs/TextInput"
import Swal from "sweetalert2/dist/sweetalert2.all"
import SelectInput from "./CustomInputs/SelectInput"
import TextArea from "./CustomInputs/TextArea";
import ProviderFormInline from "./InlineForms/ProviderFormInline"

function CarForm(props) {

    const [name, setName] = useState("")
    const [plate, setPlate] = useState("")
    const [provider, setProvider] = useState([])

    const handleSubmit = (e) => {
        e.preventDefault();
        let data = {name: name, plate: plate, provider: provider.value}
        api.createCar(data).then(res =>{
            Swal.fire("Başarılı", "Müşteri kaydı tamamlandı", "success")
            console.log(res.data);
        }).catch(err => {
            Swal.fire("Başarısız", "", "error")
        })
    }
    const handleName = (val) => {
        setName(val);
    }
    const handleProvider = (val) => {
        setProvider(val);
    }
    const handlePlate = (val) => {
        setPlate(val);
    }


    return (
        <div className="col-12 mt-4">
            <form onSubmit={handleSubmit}>
                <TextInput type="text" name="car" label="Araba Adı" passValue={handleName}/>
                <TextInput type="text" name="car_plate" label="Plaka Numarası" placeholder="TR 35 TUV 60" passValue={handlePlate}/>
                <SelectInput type="provider" passValue={handleProvider} name="car-provider" label="Sağlayıcı Seçiniz" />
                <button type="submit" className="btn btn-primary float-right w-25">Onayla</button>
            </form>
        </div>
    )
}

export default CarForm
