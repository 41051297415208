import React, { useState } from 'react'
import SelectInput from "../../MainAddComps/Forms/CustomInputs/SelectInput"
import TextInput from "../../MainAddComps/Forms/CustomInputs/TextInput"
import Swal from "sweetalert2/dist/sweetalert2.all"

function DriverEditFormInline(params) {
    const [name, setName] = useState("");
    const [mail, setMail] = useState("");
    const [phone, setPhone] = useState("");

    const handleName = (val) => {
        setName(val)
    }
    const handleMail = (val) => {
        setMail(val)
    }
    const handlePhone = (val) => {
        setPhone(val)
    }
    const handleSubmit = () => {
        let data = { id: params.id, name: name, email: mail, phone: phone }
        api.editDriver(data).then(res => {
            console.log("edit res.data", res.data)
            if (res.data[0] == "success") {
                Swal.fire("Başarılı", "Düzenleme işleminiz başarıyla gerçekleşmiştir", "success").then((result) => {
                    if (result.isConfirmed) {
                        params.endEditing(data, params.id);
                    }
                })
            } else {
                Swal.fire("Başarısız", "Bir hatayla karşılaşıldı", "error")
            }
        }).catch(err => {
            console.log("edit err", err)
            Swal.fire("Başarısız", "Bir hatayla karşılaşıldı", "error")
        })
    }
    const handleCancel = (e) => {
        e.preventDefault()
        params.endEditing("endEdit", 0);
    }
    let row = params.row
    return (row ?
        <tr>
            <th className="table-id"># {params.id}</th>
            <td>
                <TextInput type="text" passValue={handleName} initialValue={row["name"]} />
            </td>
            <td>
                <TextInput type="text" passValue={handleMail} initialValue={row["email"]} />
            </td>
            <td>
                <TextInput type="text" passValue={handlePhone} initialValue={row["phone"]} />
            </td>
            <td className="table-islem1">
                <div className="p-2">
                    <a href="#" onClick={handleSubmit} className="btn btn-sm btn-primary w-100 mb-2">Onayla</a>
                    <a href="#" onClick={handleCancel} className="btn btn-sm btn-danger w-100 mb-2">İptal</a>
                </div>
            </td>
        </tr> : null
    )
}

export default DriverEditFormInline
