import React, { useState } from 'react'
import TextInput from "../MainAddComps/Forms/CustomInputs/TextInput"
import TextArea from "../MainAddComps/Forms/CustomInputs/TextArea"
import SelectInput from "../MainAddComps/Forms/CustomInputs/SelectInput"
import ExtraNav from "./ExtraNav"
import FileInput from "../MainAddComps/Forms/CustomInputs/FileInput"
import ExtraFormCont from "./ExtraFormCont"
import Swal from "sweetalert2/dist/sweetalert2.all"
import api from '../../api'

const RezervationAdd = () => {
    const [nav, setNav] = useState("");
    const [customer, setCustomer] = useState(0);
    const [otelExtras, setOtelExtras] = useState([]);
    const [carExtras, setCarExtras] = useState([]);
    const [ticketExtras, setTicketExtras] = useState([]);
    const [otherExtras, setOtherExtras] = useState([]);
    const [files, setFiles] = useState([]);

    const handleSubmit = (e) => {
        e.preventDefault();

        let data = { customer: customer, otel_extras: otelExtras, car_extras: carExtras, ticket_extras: ticketExtras, other_extras: otherExtras }


        api.createRezervation(data).then(res => {
            if (res.data[0] == "success") {
                console.log("data", res.data)


                // ============= File upload
                let fileData = new FormData();
                for (let i = 0; i < files.length; i++) {
                    fileData.append("files[]", files[i]);
                }
                fileData.append("rezervation_id", res.data[1])
                api.uploadFile(fileData).then(res1 => {
                    console.log("file upload success", res1.data);
                }).catch(err => {
                    console.log("file err: ", err);
                })
                // ============ END file upload

                Swal.fire("Başarılı", "Rezervasyon kaydı tamamlandı", "success")

            } else {
                console.log("else: ", res.data)
                Swal.fire("Başarısız", res.data, "error")
            }
        }).catch(err => {
            console.log("err: ", err)
            Swal.fire("Başarısız", "", "error")
        })
    }
    const handleNav = (val) => {
        setNav(val);
    }
    const handleCustomer = (val) => {
        setCustomer(val.value);
    }
    const handleFiles = (val) => {
        setFiles(val);
    }
    const handleExtras = (type, extras) => {
        if (type == "otel") {
            setOtelExtras(extras);
        } else if (type == "car") {
            setCarExtras(extras);
        } else if (type == "ticket") {
            setTicketExtras(extras);
        } else if (type == "other") {
            setOtherExtras(extras);
        }
    }
    return (
        <div className="col-12 mt-4 px-5">
            <form onSubmit={handleSubmit}>
                <SelectInput type="customer" name="rezervation-customer" label="Müşteri Seçiniz" passValue={handleCustomer} />
                <FileInput passValue={handleFiles} name="rezervation-file" label="Eklenicek Dosyaları Seçiniz" />
                <div className="extra-cont" style={{ zIndex: -10 }}>
                    <ExtraNav passActive={handleNav} />
                    <div className="bg-light border border-secondary rounded my-3">
                        <ExtraFormCont carExtras={carExtras} otelExtras={otelExtras} ticketExtras={ticketExtras} otherExtras={otherExtras} passExtras={handleExtras} active={nav} />
                    </div>
                </div>
                <button type="submit" className="btn btn-primary float-right w-25">Onayla</button>
            </form>
        </div>
    )
}

export default RezervationAdd
