import React, { useState } from 'react'
import TextInput from '../../MainAddComps/Forms/CustomInputs/TextInput'

function OtherExtraAddInline(params) {
    const [name, setName] = useState("")
    const [date, setDate] = useState("")
    const [buy, setBuy] = useState("")
    const [sell, setSell] = useState("")


    const handleName = (val) => {
        setName(val);
    }
    const handleDate = (val) => {
        setDate(val);
    }
    const handleBuy = (val) => {
        setBuy(val);
    }
    const handleSell = (val) => {
        setSell(val);
    }

    const handleSubmit = (e) => {
        e.preventDefault()
        let data = { rezervation_id: params.id, name: name, buy: buy, sell: sell, date: date, info: "" }
        api.addOtherExtra(data).then(res => {
            if (res.data[0] == "success") {
                console.log("success", res.data)
                params.endAdding(res.data[1], params.id);
            } else {
                console.log("db err", res.data)
            }
        }).catch(err => {
            console.log("js err", res.data)
        })
    }
    
    const handleCancel = (e) => {
        e.preventDefault()
        params.endAdding("endAdd", 0)
    }

    return (
        <div style={{
            display: "grid",
            gridTemplateColumns: "30% 70%",
            borderBottom: "1px solid #343a40",
            padding: "10px 0"
        }}>
            <b>Extra İsmi: </b> <TextInput style="small" passValue={handleName} />

            <b>Tarih: </b> <TextInput style="small" passValue={handleDate} />

            <b>Alış Fiyatı: </b> <TextInput style="small" passValue={handleBuy} />

            <b>Satış Fiyatı: </b> <TextInput style="small" passValue={handleSell} />

            <div style={{ gridColumn: "1/3" }}>
                <button onClick={handleSubmit} className="btn btn-sm btn-primary w-100 mt-2">Diğer Extra Ekle</button>
                <button onClick={handleCancel} className="btn btn-sm btn-danger w-100 mt-2">İptal</button>
            </div>
        </div>
    )
}

export default OtherExtraAddInline
