import React from 'react'

function Settings() {
    return (
        <div className="row">
            <div className="col-12 p-5">
                <h5>Kullanıcı Bilgileri</h5>
                <b>Kullanıcı Adı:</b> <span>admin</span>
                <br />
                <b>Şifre: </b> <span>NMMyV3hJCh</span>
            </div>
        </div>
    )
}

export default Settings
