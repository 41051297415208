import React, { useState, useEffect } from 'react'
import OtherExtra from "./OtherExtra"
import uuid from 'react-uuid'

function OtherExtraForm(params) {
    const [extras, setExtras] = useState(params.extras);

    const handleExtras = (val) => {
        setExtras([...extras, val]);
    }
    const deleteExtra = (e) => {
        e.preventDefault();
        let id = e.target.name;
        let newExtras = extras;
        newExtras = newExtras.filter(ext => { return ext.id != id })
        setExtras(newExtras);
    }

    useEffect(() => {
        params.passValue(extras);
    }, [extras])

    return (
        <div className="col-12">
            <OtherExtra passValue={handleExtras} />
            <div className="row row-cols-1 row-cols-md-2 row-cols-md-3">
                {params.extras.map(ex => {
                    return (
                        <div key={uuid()} className="col mb-3">
                            <div className="card border-dark" style={{ fontSize: "12px" }}>
                                <div className="card-header">
                                    <b> Extra İsmi: </b> {ex.name}
                                    <button name={ex.id} onClick={deleteExtra} className="btn btn-sm btn-danger float-right">Sil</button>
                                </div>
                                <div className="card-body text-dark">
                                    <p className="card-text"> <b> Alış Fiyatı: </b> {ex.buy}</p>
                                    <p className="card-text"> <b> Satış Fiyatı: </b> {ex.sell}</p>
                                    <p className="card-text"> <b> Tarih: </b> {ex.date}</p>
                                    <p className="card-text"> <b> Bilgi: </b> {ex.info}</p>
                                </div>
                            </div>
                        </div>
                    )
                })}
            </div>
        </div>
    )
}

export default OtherExtraForm
