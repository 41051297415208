import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSearch } from '@fortawesome/free-solid-svg-icons'
import React, { useEffect, useState } from "react";


function SearchArea(params) {
    const [value, setValue] = useState("");
    const onChange = (e) => {
        setValue(e.target.value);
    }
    const passSearchValue = (e) => {
        if (e.key == "Enter") {
            params.passSearchValue(value);
        }
    }
    const passWithClick = (e) => {
        e.preventDefault();
        params.passSearchValue(value);
    }
    return (
        <div className="form-inline search-area">
            <div className="input-group search-area">
                <input value={value} onChange={onChange} onKeyDown={passSearchValue} type="text" className="form-control" placeholder="Ara.." aria-label="Ara.." aria-describedby="basic-addon1" />
                <div onClick={passWithClick} className="input-group-append">
                    <span className="input-group-text" id="basic-addon2">
                        <FontAwesomeIcon icon={faSearch} />
                    </span>
                </div>
            </div>
        </div>
    );
}
export default SearchArea