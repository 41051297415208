import React, { useState } from 'react'
import TextInput from "../../MainAddComps/Forms/CustomInputs/TextInput"
import TextArea from "../../MainAddComps/Forms/CustomInputs/TextArea"
import DatePicker from "react-datepicker"
import tr from "date-fns/locale/tr"
import uuid from 'react-uuid'

function OtherExtra(params) {
    const [name, setName] = useState("");
    const [date, setDate] = useState(new Date());
    const [info, setInfo] = useState("");
    const [buy, setBuy] = useState(0);
    const [sell, setSell] = useState(0);

    const handleName = (val) => {
        setName(val);
    }
    const handleDate = (val) => {
        setDate(val);
    }
    const handleInfo = (val) => {
        setInfo(val);
    }
    const handleBuy = (val) => {
        setBuy(val);
    }
    const handleSell = (val) => {
        setSell(val);
    }
    const handleSubmit = (e) => {
        e.preventDefault();
        let data = {
            name: name,
            date: date.toLocaleString(),
            buy: buy,
            sell: sell,
            info: info,
            id: uuid()
        }
        params.passValue(data);
    }
    return (
        <div className="col-12 mt-4">
            <TextInput type="text" name="other-name" label="Extra Adı" passValue={handleName} />
            <div className="form-group">
                <label >Tarihi Seçiniz</label>
                <DatePicker
                    selected={date}
                    onChange={handleDate}
                    wrapperClassName="form-control"
                    className="form-control"
                    timeCaption="Zaman"
                    showTimeSelect
                    locale={tr}
                    timeFormat="HH:mm"
                    dateFormat="dd/MM/yyyy HH:mm"
                />
            </div>
            <TextInput type="text" name="other-buy-price" label="Alış Fiyatını Giriniz" placeholder="22.50" passValue={handleBuy} />
            <TextInput type="text" name="other-sell-price" label="Satış Fiyatını Giriniz" placeholder="31.50" passValue={handleSell} />
            <TextArea name="other-info" label="Bilgi" passValue={handleInfo} />
            <div className="text-right">
                <button onClick={handleSubmit} className="btn btn-danger w-25 mb-4">Extra Ekle</button>
            </div>
        </div>
    )
}

export default OtherExtra
