const axios = window.axios;

let BASE_API_URL;

const current_url = window.location.href;

if (current_url.includes("localhost")) {
    BASE_API_URL = "https://localhost/akiktour/table-app/public"
} else {
    BASE_API_URL = "https://demo.akiktour.com"
}

export default {
    createDatabase: () =>
        axios.get(BASE_API_URL + "/createDatabase"),
    getTables: () =>
        axios.get(BASE_API_URL + "/getTables"),
    getCustomers: () =>
        axios.get(BASE_API_URL + "/getCustomers"),
    getProviders: () =>
        axios.get(BASE_API_URL + "/getProviders"),
    getOtels: () =>
        axios.get(BASE_API_URL + "/getOtels"),
    getRezervations: () =>
        axios.get(BASE_API_URL + "/getRezervations"),
    getCars: () =>
        axios.get(BASE_API_URL + "/getCars"),
    getDrivers: () =>
        axios.get(BASE_API_URL + "/getDrivers"),
    getRoomTypes: (otel_id) =>
        axios.get(BASE_API_URL + "/getRoomTypes", {
            params: {
                otel_id: otel_id
            }
        }),


    // create requests
    createRezervation: (data) =>
        axios.post(BASE_API_URL + "/createRezervation", data),
    createOtel: (data) =>
        axios.post(BASE_API_URL + "/createOtel", data),
    createExtra: (data) =>
        axios.post(BASE_API_URL + "/createExtra", data),
    createDriver: (data) =>
        axios.post(BASE_API_URL + "/createDriver", data),
    createCar: (data) =>
        axios.post(BASE_API_URL + "/createCar", data),
    createProvider: (data) =>
        axios.post(BASE_API_URL + "/createProvider", data),
    createResponsible: (data) =>
        axios.post(BASE_API_URL + "/createResponsible", data),
    createCustomer: (data) =>
        axios.post(BASE_API_URL + "/createCustomer", data),
    uploadFile: (data) =>
        axios.post(BASE_API_URL + "/uploadFile", data),

    // edit requests
    editRezervation: (data) =>
        axios.post(BASE_API_URL + "/editRezervation", data),
    editCustomer: (data) =>
        axios.post(BASE_API_URL + "/editCustomer", data),
    editCar: (data) =>
        axios.post(BASE_API_URL + "/editCar", data),
    editProvider: (data) =>
        axios.post(BASE_API_URL + "/editProvider", data),
    editResponsible: (data) =>
        axios.post(BASE_API_URL + "/editResponsible", data),
    editOtel: (data) =>
        axios.post(BASE_API_URL + "/editOtel", data),
    editDriver: (data) =>
        axios.post(BASE_API_URL + "/editDriver", data),

    // add requests    
    addOtelExtra: (data) =>
        axios.post(BASE_API_URL + "/addOtelExtra", data),
    addCarExtra: (data) =>
        axios.post(BASE_API_URL + "/addCarExtra", data),
    addOtherExtra: (data) =>
        axios.post(BASE_API_URL + "/addOtherExtra", data),

    // delete request
    deleteRow: (data) =>
        axios.post(BASE_API_URL + "/deleteRow", data),

}