import WindowTable from './WindowTable'
import React, { useEffect, useState } from "react";
import uuid from 'react-uuid';


const Tables = (params) => {
    const [data, setData] = useState(() => params.data);
    const [value, setValue] = useState(params.searchValue)
    
    let active = params.active ?? {};
    active = active.hasOwnProperty("name") ? active.name : "";
    let orders = data["orders"] ?? []
    let order = orders.find(order => {
        return order["name"] == active;
    });
    
    useEffect(() => {
        setValue(params.searchValue)
    }, [params.searchValue])
    
    useEffect(() => {
        setValue("");
    }, [params.active])

    const passId = (id) =>{
        params.passId(id)
    }
    return (
        <div className="tables-container col-12 row px-0 ml-0">
            <div style={{height: "533px"}} className="border border-dark col-12 px-0">
                <WindowTable passId={passId} searchValue={value} data={[data[active], order]} />
            </div>
        </div>
    );

}
export default Tables;