import React, { useState, useEffect } from "react";

function TextInput(params) {
    if (params.type == "price") {
        const [number, setNumber] = useState(0)
        const [decimal, setDecimal] = useState(0)

        const handleChangeNum = (e) => {
            let num = e.target.value;
            num = num == "" ? 0 : parseInt(num)
            setNumber(num)
        }
        const handleChangeDec = (e) => {
            let dec = e.target.value;
            dec = dec == "" ? 0 : parseInt(dec)
            // console.log(decimal)
            setDecimal(dec)
        }
        useEffect(() => {
            let newDec = decimal;
            newDec = "" + newDec;
            newDec = newDec.length == 1 ? newDec + "0" : newDec;
            let value = number + "." + newDec;
            params.passValue(value);
        }, [number, decimal])
        return (
            <div className="form-group">
                {params.label ? <label>{params.label}</label> : null}
                <div className="input-group">
                    <div className="input-group-prepend">
                        <span className="input-group-text">
                            {number + "," + (("" + decimal).length == 1 ? decimal + "0" : decimal)} ₺
                        </span>
                    </div>
                    <input value={number} type="text" onChange={handleChangeNum} className="form-control" maxLength="6" />
                    <input value={decimal} type="text" onChange={handleChangeDec} className="form-control" maxLength="2" />
                </div>
            </div>
        )

    } else {
        const [value, setValue] = useState(params.initialValue ? value ?? params.initialValue : "")
        const handleChange = (e) => {
            setValue(e.target.value)
        }
        useEffect(() => {
            params.passValue(value);
        }, [value])
        return (
            <div className={params.style == "small" ? "" : "form-group"}>
                {params.label ? <label htmlFor={params.name}>{params.label}</label> : null}
                <input
                    type={params.type}
                    onChange={handleChange}
                    value={value}
                    name={params.name}
                    className={params.style == "small" ? "" : "form-control"}
                    id={params.name}
                    placeholder={params.placeholder ?? params.label}
                    style={params.style ? {
                        width: "100%",
                    } : {}}
                />
            </div>
        )
    }
}

export default TextInput
