import TableSelectButtonsContainer from "./TableSelectButtonsContainer"
import Tables from "./Tables"
import SearchArea from './SearchArea'
import React, { useEffect, useState } from "react";


function TablesContainer(params) {
    const [tables, setTables] = useState(0);
    const [names, setNames] = useState([]);
    const [searchValue, setSearchValue] = useState("");

    useEffect(() => {
        if (tables == 0) {
            api.getTables()
                .then(res => {
                    setTables(res.data);
                    console.log("getTables: success")
                    console.log("api res.data", res.data);
                    setNames(getTranslitions(res.data["orders"]));
                })
                .catch(error => {
                    console.log("error", error);
                });
        }
    }, [tables])

    const getTranslitions = (data) => {
        let i = 0;
        let searchValue = "";
        let names = data.map(order => {
            i++;
            return {
                name: order["name"],
                turkish: order["header"],
                active: i == 1 ? true : false
            }
        })
        return names;
    }
    const changeActive = (name) => {
        
        let newNames = names;
        newNames = newNames.map(n => {
            n.active = false;
            if (n.turkish == name) {
                n.active = true;
            }
            return n;
        })
        setNames(newNames);
    }

    const changeActiveEng = (name) => {
        
        let newNames = names;
        newNames = newNames.map(n => {
            n.active = false;
            if (n.name.includes(name)) {
                console.log(name, n.name)
                n.active = true;
            }
            return n;
        })
        setNames(newNames);
    }

    const getSearchValue = (val) => {
        setSearchValue(val);
    }
    const passId = (val) => {
        console.log(val);
        val = val.split("###");
        let linkName = val[0];
        let linkTab = val[1];
        changeActiveEng(linkTab)
        setTimeout(() => setSearchValue(linkName), 100);
        
    }

    if (tables == 0) {
        return <h1>Yükleniyor...</h1>
    } else {
        return (
            <div>
                <div className="mb-3 float-right">
                    <SearchArea passSearchValue={getSearchValue} />
                </div>

                <TableSelectButtonsContainer passActive={changeActive} data={names} />
                <Tables passId={passId} searchValue={searchValue} active={names.find(n => n.active)} data={tables} />
            </div>
        );
    }
}
export default TablesContainer;