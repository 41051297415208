import React from 'react'
import OtelExtraAddInline from "./OtelExtraAddInline"
import CarExtraAddInline from "./CarExtraAddInline"
import OtherExtraAddInline from "./OtherExtraAddInline"
import FileExtraAddInline from './FileExtraAddInline'

function AddExtraInlineForm(params) {
    const endAddingOtel = (data, id) => {
        params.endAdding(data, id, "otel")
    }
    const endAddingCar = (data, id) => {
        params.endAdding(data, id, "car")
    }
    const endAddingOther = (data, id) => {
        params.endAdding(data, id, "other")
    }
    const endAddingFile = (data, id) => {
        params.endAdding(data, id, "file")
    }

    if (params.type == "otel") {
        return <OtelExtraAddInline id={params.id} endAdding={endAddingOtel} />
    } else if (params.type == "car") {
        return <CarExtraAddInline id={params.id} endAdding={endAddingCar} />
    } else if (params.type == "other") {
        return <OtherExtraAddInline id={params.id} endAdding={endAddingOther} />
    } else if (params.type == "file") {
        return <FileExtraAddInline id={params.id} endAdding={endAddingFile} />
    }
}

export default AddExtraInlineForm
