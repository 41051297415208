import React, { useState, useEffect } from 'react'
import OtelExtra from "./OtelExtra"
import uuid from 'react-uuid'

function OtelExtraForm(params) {
    const [extras, setExtras] = useState(params.extras);

    const handleExtras = (val) => {
        setExtras([...extras, val]);
    }
    const deleteExtra = (e) => {
        e.preventDefault();
        let id = e.target.name;
        let newExtras = extras;
        newExtras = newExtras.filter(ext => { return ext.id != id })
        setExtras(newExtras);
    }

    useEffect(() => {
        params.passValue(extras);
    }, [extras])
    return (
        <div className="col-12 mt-4">
            <OtelExtra passValue={handleExtras} />
            <div className="row row-cols-1 row-cols-md-2 row-cols-md-3">
                {params.extras.map(ex => {
                    return (
                        <div key={uuid()} className="col mb-3">
                            <div className="card border-dark" style={{ fontSize: "12px" }}>
                                <div className="card-header"> <b> Otel İsmi: </b> {ex.otel_name} <button name={ex.id} onClick={deleteExtra} className="btn btn-sm btn-danger float-right">Sil</button> </div>
                                <div className="card-body text-dark">
                                    <p className="card-text"> <b> Giriş Zamanı: </b> {ex.entry_time}</p>
                                    <p className="card-text"> <b> Çıkış Zamanı: </b> {ex.exit_time}</p>
                                    <p className="card-text"> <b> Konfirmasyon Numarası: </b> {ex.confNum}</p>
                                    <p className="card-text"> <b> Yetişkin Sayısı: </b> {ex.people_count["Yetişkin Sayısı"]}</p>
                                    <p className="card-text"> <b> Çocuk Sayısı: </b> {ex.people_count["Çocuk Sayısı"]}</p>
                                    <p className="card-text"> <b> Çocuk Yaş: </b> {ex.people_count["Çocuk Yaş"]}</p>
                                    <p className="card-text"> <b> Odalar </b></p>
                                    <table className="table table-sm">
                                        <thead>
                                            <tr>
                                                <th className="col">Oda Tipi</th>
                                                <th className="col">Alış Fiyatı</th>
                                                <th className="col">Satış Fiyatı</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {ex.roomExtras.map(r => {
                                                return (
                                                    <tr>
                                                        <td>{r.roomType}</td>
                                                        <td>{r.buy}</td>
                                                        <td>{r.sell}</td>
                                                    </tr>
                                                )
                                            })}

                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    )
                })}
            </div>
        </div>
    )
}

export default OtelExtraForm
