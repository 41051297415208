import RezervationForm from "./Forms/RezervationForm";
import OtelForm from "./Forms/OtelForm";
import ExtraForm from "./Forms/ExtraForm"
import DriverForm from "./Forms/DriverForm";
import CarForm from "./Forms/CarForm";
import ProviderForm from "./Forms/ProviderForm";
import ResponsibleForm from "./Forms/ResponsibleForm";
import CustomerForm from "./Forms/CustomerForm";

function MainAddForms(params) {
    let activeForm;
    if (params.active == "otel") {
        activeForm = <OtelForm />;
    } else if (params.active == "driver") {
        activeForm = <DriverForm />;
    } else if (params.active == "provider") {
        activeForm = <ProviderForm />;
    } else if (params.active == "car") {
        activeForm = <CarForm />;
    } else if (params.active == "customer") {
        activeForm = <CustomerForm />;
    } else{
        activeForm = <OtelForm />;
    }
    return (
        <div className="col-12">
            {activeForm}
        </div>
    )
}

export default MainAddForms
