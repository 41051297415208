import uuid from 'react-uuid';
import TableSelectButton from './TableSelectButton'
import React from "react";

const TableSelectButtonsContainer = (params) => {
    const passActive = (name) => {
        params.passActive(name)
    }
    return (
        <div className="col-12 px-0 btn-group btn-group-toggle rounded-top" data-toggle="buttons">
            {params.data.map(btn =>
                <TableSelectButton 
                    passActive={passActive} 
                    key={uuid()} 
                    name={btn.turkish} 
                    active={btn.active} />
            )}
        </div>
    );

}

export default TableSelectButtonsContainer;