import React, { useState, useEffect } from "react";

function TextArea(params) {
    const [value, setValue] = useState(params.value ?? "")

    const handleChange = (e) => {
        setValue(e.target.value)
    }
    useEffect(() => {
        params.passValue(value);
    }, [value])

    return (
        <div className={params.style == "small" ? "w-100" : "form-group"}>
            {params.label ? <label htmlFor={params.name}>{params.label}</label> : null}
            <textarea
                style={{ fontSize: params.fontSize ?? "1rem" }}
                value={value}
                onChange={handleChange}
                className="form-control"
                id={params.name}
                name={params.name}
                rows={params.rows ?? "4"}
            />
        </div>
    )
}

export default TextArea
