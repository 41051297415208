import React, { useState } from 'react'
import uuid from 'react-uuid'
import SelectInput from "../../MainAddComps/Forms/CustomInputs/SelectInput"
import TextInput from "../../MainAddComps/Forms/CustomInputs/TextInput"
import TextArea from "../../MainAddComps/Forms/CustomInputs/TextArea"
import Swal from "sweetalert2/dist/sweetalert2.all"

function RezervationEditFormInline(params) {
    const [otelExtras, setOtelExtras] = useState(params.row["otel_extras"])
    const [carExtras, setCarExtras] = useState(params.row["car_extras"])
    const [otherExtras, setOtherExtras] = useState(params.row["other_extras"])
    const [customer, setCustomer] = useState({
        name: params.row["customer_name"],
        id: params.row["customer_id"]
    })
    const [files, setFiles] = useState(null)
    // Altta genel formları tamamla
    // Ayrıca bu formların hepsine normal değerlerini çekmen lazım
    // Ayrıca En son işlemler diye bir tag açman lazım 
    // Orada Değişiklikleri Onayla olması lazım
    // Değişiklikler onaylanınca istek gönderilicek controller a
    // Controllerda da bir function yazılıcak bunun için
    // Onayladıktan sonra edit bitmiş olucak normal haline dönücek

    // =========== ALL THE HANDLES =============
    // Otel Extras
    const handleOtelName = (val, id) => {
        let newState = otelExtras
        let index = newState.findIndex(state => state.id == id)
        let newElement = newState[index]
        newElement.otel_name = val.label
        newElement.otel_id = val.value
        setOtelExtras([...newState])
    }
    const handleOtelEntry = (val, id) => {
        let newState = otelExtras

        let index = newState.findIndex(state => state.id == id)
        let newElement = newState[index]
        newElement.entry_time = val

        setOtelExtras(newState)
    }
    const handleOtelExit = (val, id) => {
        let newState = otelExtras
        let index = newState.findIndex(state => state.id == id)
        let newElement = newState[index]
        newElement.exit_time = val
        setOtelExtras(newState)
    }
    const handleConfirmation = (val, id) => {
        let newState = otelExtras
        let index = newState.findIndex(state => state.id == id)
        let newElement = newState[index]
        newElement.confirmation_number = val
        setOtelExtras(newState)
    }
    const handleAdultCount = (val, id) => {
        let newState = otelExtras
        let index = newState.findIndex(state => state.id == id)
        let newElement = newState[index]
        if (typeof (newElement.people_count) == "string") {
            newElement.people_count = {
                "Yetişkin Sayısı": parseInt(val),
                "Çocuk Sayısı": 0,
                "Çocuk Yaş": 0
            }
        } else {
            newElement.people_count["Yetişkin Sayısı"] = parseInt(val)
        }
        setOtelExtras(newState)
    }
    const handleKidCount = (val, id) => {
        let newState = otelExtras
        let index = newState.findIndex(state => state.id == id)
        let newElement = newState[index]
        newElement.people_count["Çocuk Sayısı"] = parseInt(val)
        setOtelExtras(newState)
    }
    const handleKidAge = (val, id) => {
        let newState = otelExtras
        let index = newState.findIndex(state => state.id == id)
        let newElement = newState[index]
        newElement.people_count["Çocuk Yaş"] = parseInt(val)
        setOtelExtras(newState)
    }
    // ------- ROOM EXTRAS ---------
    const handleRoomType = (val, roomEx_id, otelEx_id) => {
        let newOtelExtras = otelExtras
        let otelExtraIndex = newOtelExtras.findIndex(otelExtra => otelExtra.id == otelEx_id)
        let newOtelExtra = newOtelExtras[otelExtraIndex];
        if (newOtelExtra.room_extras) {
            let newRoomExtras = newOtelExtra.room_extras
            let roomExtraIndex = newRoomExtras.findIndex(roomExtra => roomExtra.id == roomEx_id)
            let newRoomExtra = newRoomExtras[roomExtraIndex]
            newRoomExtra.room_type = val
        }
        setOtelExtras(newOtelExtras)
    }
    const handleRoomPurchase = (val, roomEx_id, otelEx_id) => {
        let newOtelExtras = otelExtras
        let otelExtraIndex = newOtelExtras.findIndex(otelExtra => otelExtra.id == otelEx_id)
        let newOtelExtra = newOtelExtras[otelExtraIndex];
        if (newOtelExtra.room_extras) {
            let newRoomExtras = newOtelExtra.room_extras
            let roomExtraIndex = newRoomExtras.findIndex(roomExtra => roomExtra.id == roomEx_id)
            let newRoomExtra = newRoomExtras[roomExtraIndex]
            newRoomExtra.purchase_price = val
        }
        setOtelExtras(newOtelExtras)
    }
    const handleRoomSale = (val, roomEx_id, otelEx_id) => {
        let newOtelExtras = otelExtras
        let otelExtraIndex = newOtelExtras.findIndex(otelExtra => otelExtra.id == otelEx_id)
        let newOtelExtra = newOtelExtras[otelExtraIndex];
        if (newOtelExtra.room_extras) {
            let newRoomExtras = newOtelExtra.room_extras
            let roomExtraIndex = newRoomExtras.findIndex(roomExtra => roomExtra.id == roomEx_id)
            let newRoomExtra = newRoomExtras[roomExtraIndex]
            newRoomExtra.sale_price = val
        }
        setOtelExtras(newOtelExtras)
    }
    // ------- ROOM EXTRAS END --------
    // CAR EXTRAS
    const handleCarName = (val, id) => {
        let newState = carExtras
        let index = newState.findIndex(state => state.id == id)
        let newElement = newState[index]
        newElement.car_name = val.label
        newElement.car_id = val.value
        setCarExtras(newState)
    }
    const handleDriverName = (val, id) => {
        let newState = carExtras
        let index = newState.findIndex(state => state.id == id)
        let newElement = newState[index]
        newElement.driver_name = val.label
        newElement.driver_id = val.value
        setCarExtras(newState)
    }
    const handleProviderName = (val, id) => {
        let newState = carExtras
        let index = newState.findIndex(state => state.id == id)
        let newElement = newState[index]
        newElement.provider_name = val.label
        newElement.provider_id = val.value
        setCarExtras(newState)
    }
    const handlePickup = (val, id) => {
        let newState = carExtras
        let index = newState.findIndex(state => state.id == id)
        let newElement = newState[index]
        newElement.pickup_date = val
        setCarExtras(newState)
    }
    const handlePickoff = (val, id) => {
        let newState = carExtras
        let index = newState.findIndex(state => state.id == id)
        let newElement = newState[index]
        newElement.pickoff_date = val
        setCarExtras(newState)
    }
    const handleStartKm = (val, id) => {
        let newState = carExtras
        let index = newState.findIndex(state => state.id == id)
        let newElement = newState[index]
        newElement.start_km = val
        setCarExtras(newState)
    }
    const handleEndKm = (val, id) => {
        let newState = carExtras
        let index = newState.findIndex(state => state.id == id)
        let newElement = newState[index]
        newElement.end_km = val
        setCarExtras(newState)
    }
    const handleCarPurchase = (val, id) => {
        let newState = carExtras
        let index = newState.findIndex(state => state.id == id)
        let newElement = newState[index]
        newElement.purchase_price = val
        setCarExtras(newState)
    }
    const handleCarSale = (val, id) => {
        let newState = carExtras
        let index = newState.findIndex(state => state.id == id)
        let newElement = newState[index]
        newElement.sale_price = val
        setCarExtras(newState)
    }
    const handleExtraCharges = (val, id) => {
        let newState = carExtras
        let index = newState.findIndex(state => state.id == id)
        let newElement = newState[index]
        newElement.extra_charges = val
        setCarExtras(newState)
    }
    // Other Extras
    const handleOtherName = (val, id) => {
        let newState = otherExtras
        let index = newState.findIndex(state => state.id == id)
        let newElement = newState[index]
        newElement.name = val
        setOtherExtras(newState)
    }
    const handleOtherDate = (val, id) => {
        let newState = otherExtras
        let index = newState.findIndex(state => state.id == id)
        let newElement = newState[index]
        newElement.date = val
        setOtherExtras(newState)
    }
    const handleOtherPurchase = (val, id) => {
        let newState = otherExtras
        let index = newState.findIndex(state => state.id == id)
        let newElement = newState[index]
        newElement.purchase_price = val
        setOtherExtras(newState)
    }
    const handleOtherSell = (val, id) => {
        let newState = otherExtras
        let index = newState.findIndex(state => state.id == id)
        let newElement = newState[index]
        newElement.sell_price = val
        setOtherExtras(newState)
    }
    // Customer
    const handleCustomerName = (val) => {
        let newState = customer
        newState.name = val.label
        newState.id = val.value
        setCustomer(newState)
    }
    // Handle Submit
    const handleSubmit = (e) => {
        e.preventDefault()
        let data = { id: params.id, customer: customer, otelExtras: otelExtras, carExtras: carExtras, otherExtras: otherExtras }
        api.editRezervation(data).then(res => {
            console.log("editRezervation res.data", res.data)
            if (res.data[0] == "success") {
                Swal.fire("Başarılı", "Rezervasyon düzenleme işleminiz başarıyla gerçekleşmiştir", "success").then((result) => {
                    if (result.isConfirmed) {
                        params.endEditing(customer, params.id);
                    }
                })
            } else {
                Swal.fire("Başarısız", "Bir hatayla karşılaşıldı", "error")
            }
        }).catch(err => {
            console.log("editRezervation err", err)
            Swal.fire("Başarısız", "Bir hatayla karşılaşıldı", "error")
        })
    }

    const handleCancel = (e) => {
        e.preventDefault()
        params.endEditing("endEdit", 0);
    }
    // =========== ALL THE HANDLES END =============
    let row = params.row;
    return (
        <tr>
            <th className="table-id"># {params.id}</th>
            {params.keys.map(key => {
                if (key == "otel_extras") {
                    return (
                        <td key={uuid()}>
                            {row[key].map((e, index) => {
                                return (
                                    // Burda birden çok form olucak extralar için 
                                    <div style={{
                                        display: "grid",
                                        gridTemplateColumns: "30% 70%",
                                        borderBottom: "1px solid #343a40",
                                        padding: "10px 0"
                                    }} key={uuid()}>
                                        <b>Otel ismi: </b> <SelectInput style="small" type="otel" name="table-otel-name" passValue={(val) => handleOtelName(val, e.id)}
                                            value={{ value: e.otel_id, label: e.otel_name }} />
                                        <b>Giriş zamanı: </b> <TextInput style="small" type="text" name="table-otel-entry" initialValue={e.entry_time} passValue={(val) => handleOtelEntry(val, e.id)} />

                                        <b>Çıkış zamanı: </b><TextInput style="small" initialValue={e.exit_time} passValue={(val) => handleOtelExit(val, e.id)} />

                                        <b>Konf. no: </b> <TextInput style="small" type="text" initialValue={e.confirmation_number} passValue={(val) => handleConfirmation(val, e.id)} />

                                        <b>Yetişkin Sayısı: </b> <TextInput style="small"
                                            initialValue={typeof (e.people_count) == "string" ? e.people_count.split(",")[0].replace(/[^0-9]/g, '') : e.people_count["Yetişkin Sayısı"]} passValue={(val) => handleAdultCount(val, e.id)} />
                                        <b>Çocuk Sayısı: </b> <TextInput style="small"
                                            initialValue={typeof (e.people_count) == "string" ? e.people_count.split(",")[1].replace(/[^0-9]/g, '') : e.people_count["Çocuk Sayısı"]} passValue={(val) => handleKidCount(val, e.id)} />
                                        <b>Çocuk Yaş: </b> <TextInput style="small"
                                            initialValue={typeof (e.people_count) == "string" ? e.people_count.split(",")[2].replace(/[^0-9]/g, '') : e.people_count["Çocuk Yaş"]} passValue={(val) => handleKidAge(val, e.id)} />

                                        <b style={{ gridColumnStart: "1", gridColumnEnd: "3" }}>Oda Konaklamaları:</b>
                                        <div style={{ gridColumnStart: "1", gridColumnEnd: "3" }} >
                                            {(e.room_extras).map(re => {
                                                return (
                                                    <div style={{ display: "grid", gridTemplateColumns: "40% 60%" }} key={uuid()} className="border pl-3 w-100">
                                                        <b>Oda Tipi: </b> <TextInput style="small" initialValue={re.room_type} passValue={(val) => handleRoomType(val, re.id, e.id)} />

                                                        <b>Gecelik Alış: </b> <TextInput style="small" initialValue={re.purchase_price} passValue={(val) => handleRoomPurchase(val, re.id, e.id)} />

                                                        <b>Gecelik Satış: </b> <TextInput style="small" initialValue={re.sale_price} passValue={(val) => handleRoomSale(val, re.id, e.id)} />
                                                    </div>
                                                )
                                            })}
                                        </div>
                                    </div>
                                )
                            })}
                        </td>
                    )
                } else if (key == "car_extras") {
                    return (
                        <td key={uuid()}>
                            {row[key].map(e => {
                                return (
                                    // Burda birden çok form olucak extralar için 
                                    <div style={{
                                        display: "grid",
                                        gridTemplateColumns: "30% 70%",
                                        borderBottom: "1px solid #343a40",
                                        padding: "10px 0"
                                    }} key={uuid()}>
                                        <b>Araba ismi: </b> <SelectInput style="small" type="car" name="table-otel-name" passValue={(val) => handleCarName(val, e.id)} value={{
                                            value: e.car_id,
                                            label: e.car_name
                                        }} />

                                        <b>Sürücü: </b> <SelectInput style="small" noOption={true} type="driver" name="table-otel-name" passValue={(val) => handleDriverName(val, e.id)} value={{
                                            value: e.driver_id,
                                            label: e.driver_name
                                        }} />

                                        <b>Sağlayıcı: </b> <SelectInput style="small" noOption={true} type="provider" name="table-otel-name" passValue={(val) => handleProviderName(val, e.id)} value={{
                                            value: e.provider_id,
                                            label: e.provider_name
                                        }} />

                                        <b>Alış Tarihi: </b> <TextInput style="small" initialValue={e.pickup_date} passValue={(val) => handlePickup(val, e.id)} />

                                        <b>Teslim Tarihi: </b> <TextInput style="small" initialValue={e.pickoff_date} passValue={(val) => handlePickoff(val, e.id)} />

                                        <b>Başlangıç km: </b> <TextInput style="small" initialValue={e.start_km} passValue={(val) => handleStartKm(val, e.id)} />

                                        <b>Teslim km: </b> <TextInput style="small" initialValue={e.end_km} passValue={(val) => handleEndKm(val, e.id)} />

                                        <b>Alış Fiyatı: </b> <TextInput style="small" initialValue={e.purchase_price} passValue={(val) => handleCarPurchase(val, e.id)} />

                                        <b>Satış Fiyatı: </b> <TextInput style="small" initialValue={e.sale_price} passValue={(val) => handleCarSale(val, e.id)} />

                                        <b>Extra Ücretler: </b> <TextArea style="small" fontSize="12px" rows="3" value={carExtras.extra_charges ?? e.extra_charges} passValue={(val) => handleExtraCharges(val, e.id)} />

                                    </div>
                                )
                            })}
                        </td>
                    )
                } else if (key == "other_extras") {
                    return (
                        <td key={uuid()}>
                            {row[key].map(e => {
                                return (
                                    // Burda birden çok form olucak extralar için 
                                    <div style={{
                                        display: "grid",
                                        gridTemplateColumns: "30% 70%",
                                        borderBottom: "1px solid #343a40",
                                        padding: "10px 0"
                                    }} key={uuid()}>
                                        <b>Extra ismi: </b> <TextInput style="small" initialValue={e.name} passValue={(val) => handleOtherName(val, e.id)} />

                                        <b>Tarih: </b> <TextInput style="small" initialValue={e.date} passValue={(val) => handleOtherDate(val, e.id)} />

                                        <b>Alış fiyatı: </b> <TextInput style="small" initialValue={e.purchase_price} passValue={(val) => handleOtherPurchase(val, e.id)} />

                                        <b>Satış fiyatı: </b> <TextInput style="small" initialValue={e.sell_price} passValue={(val) => handleOtherSell(val, e.id)} />

                                    </div>
                                )
                            })}
                        </td>
                    )
                } else if (key == "customer_name") {
                    return (
                        // ### Yeni Müşteri seçicek select form yaz
                        <td key={uuid()}><SelectInput style="small" type="customer" name="table-customer-name" passValue={handleCustomerName} value={{
                            value: row["customer_id"],
                            label: row["customer_name"]
                        }} /> </td>
                    )
                } else if (key == "files") {
                    return (
                        <td className="table-files" key={uuid()}>
                            {row[key].map(e => {
                                return (
                                    // Önce dosyalar sırala burda
                                    <div key={uuid()}>
                                        <a href={e.path} target="_blank">{e.file_name}</a>
                                    </div>
                                )
                            })}
                            {/* Sonra dosya ekleme formu koy buraya */}
                        </td>
                    )
                }
            })}
            <td className="table-islem1">
                <div className="p-2">
                    <a href="#" onClick={handleSubmit} className="btn btn-sm btn-primary w-100 mb-2">Onayla</a>
                    <a href="#" onClick={handleCancel} className="btn btn-sm btn-danger w-100 mb-2">Iptal</a>
                </div>
            </td>
        </tr>
    )
}

export default RezervationEditFormInline
