import React, { useState } from 'react'
import TextInput from '../../MainAddComps/Forms/CustomInputs/TextInput'
import SelectInput from '../../MainAddComps/Forms/CustomInputs/SelectInput'
import TextArea from '../../MainAddComps/Forms/CustomInputs/TextArea'

function CarExtraAddInline(params) {
    const [carId, setCarId] = useState(0)
    const [carName, setCarName] = useState("")
    const [driverId, setDriverId] = useState(0)
    const [driverName, setDriverName] = useState("")
    const [start, setStart] = useState(0)
    const [end, setEnd] = useState(0)
    const [pickup, setPickup] = useState("")
    const [pickoff, setPickoff] = useState("")
    const [extraCharges, setExtraCharges] = useState("")
    const [buy, setBuy] = useState("")
    const [sell, setSell] = useState("")
    const [providerName, setProviderName] = useState("")
    const [providerId, setProviderId] = useState(0)

    const handleCarName = (val) => {
        setCarName(val.label);
        setCarId(val.value)
    }
    const handleDriverName = (val) => {
        setDriverName(val.label);
        setDriverId(val.value);
    }
    const handleProviderName = (val) => {
        setProviderName(val.label);
        setProviderId(val.value);
    }
    const handleStart = (val) => {
        setStart(val);
    }
    const handlePickup = (val) => {
        setPickup(val);
    }
    const handlePickoff = (val) => {
        setPickoff(val);
    }
    const handleEnd = (val) => {
        setEnd(val);
    }
    const handleBuy = (val) => {
        setBuy(val);
    }
    const handleSell = (val) => {
        setSell(val);
    }
    const handleExtraCharges = (val) => {
        setExtraCharges(val);
    }

    const handleSubmit = (e) => {
        e.preventDefault()
        let data = {
            car_id: carId, car_name: carName, driver_id: driverId, driver_name: driverName, start_km: start, end_km: end, pickup_date: pickup, pickoff_date: pickoff, provider_id: providerId, provider_name: providerName, purchase_price: buy, sale_price: sell, extra_charges: extraCharges, rezervation_id: params.id
        }
        api.addCarExtra(data).then(res => {
            if (res.data[0] == "success") {
                console.log("success", res.data)
                params.endAdding(res.data[1], params.id);
            } else {
                console.log("db err", res.data)
            }
        }).catch(err => {
            console.log("js err", res.data)
        })
    }

    const handleCancel = (e) => {
        e.preventDefault()
        params.endAdding("endAdd", 0)
    }

    return (
        <div style={{
            display: "grid",
            gridTemplateColumns: "30% 70%",
            borderBottom: "1px solid #343a40",
            padding: "10px 0"
        }}>
            <b>Araba ismi: </b> <SelectInput style="small" type="car" name="111table-otel-name" passValue={handleCarName} />

            <b>Sürücü: </b> <SelectInput style="small" noOption={true} type="driver" name="111table-otel-name" passValue={handleDriverName} />

            <b>Sağlayıcı: </b> <SelectInput style="small" noOption={true} type="provider" name="111table-otel-name" passValue={handleProviderName} />

            <b>Alış Tarihi: </b> <TextInput style="small" passValue={handlePickup} />

            <b>Teslim Tarihi: </b> <TextInput style="small" passValue={handlePickoff} />

            <b>Başlangıç km: </b> <TextInput style="small" passValue={handleStart} />

            <b>Teslim km: </b> <TextInput style="small" passValue={handleEnd} />

            <b>Alış Fiyatı: </b> <TextInput style="small" passValue={handleBuy} />

            <b>Satış Fiyatı: </b> <TextInput style="small" passValue={handleSell} />

            <b>Extra Ücretler: </b> <TextArea style="small" fontSize="12px" rows="3" passValue={handleExtraCharges} />
            <div style={{ gridColumn: "1/3" }}>
                <button onClick={handleSubmit} className="btn btn-sm btn-primary w-100 mt-2">Araba Extrası Ekle</button>
                <button onClick={handleCancel} className="btn btn-sm btn-danger w-100 mt-2">İptal</button>
            </div>
        </div>
    )
}

export default CarExtraAddInline
