import React, { useState, useEffect } from 'react'
import MainAddNavButton from "../MainAddComps/MainAddNavButton"
import uuid from 'react-uuid'

const ExtraNav = (params) => {
    const btnColor = "info"
    const [buttons, setButtons] = useState([
        { name: "otel", active: true, label: "Otel Konaklamaları" },
        { name: "car", active: false, label: "Arac Kiralama" },
        { name: "ticket", active: false, label: "Ucak Bileti" },
        { name: "other", active: false, label: "Extra Harcamalar" }
    ])
    const changeActive = (type) => {
        let newButtons = buttons;
        newButtons = newButtons.map(btn => {
            btn.active = false;
            if (btn.name == type) {
                btn.active = true;
            }
            return btn;
        })
        setButtons(newButtons);
    }
    useEffect(() => {
        let activeBtn = buttons.find(btn => btn.active).name
        params.passActive(activeBtn)
    }, [buttons])

    return (
        <div className="btn-group btn-group-toggle col-12 px-0 mx-0" style={{zIndex: 0}} data-toggle="buttons">
            {buttons.map(button => {
                return (
                    <MainAddNavButton
                        key={uuid()}
                        passActive={changeActive}
                        type={button.name}
                        btnColor={btnColor}
                        active={button.active}
                        label={button.label}
                    />
                )
            })}
        </div>
    )
}

export default ExtraNav
